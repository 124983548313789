import styled from "styled-components";
import { Colors } from "theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const LabelWrapper = styled.div`
  font-size: 16px;
`

export const Button = styled.button<{isSelected: boolean}>`
  border: none;
  background-color: ${props => props.isSelected ? Colors.primary2: 'rgb(240, 243, 250)'};
  cursor: pointer;
  height: 30px;
  width: 64px;
  font-weight: ${props => props.isSelected ? '700' : '300'}
`