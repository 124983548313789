import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 10px; 
  padding: 60px;

  @media (max-width: 768px) {
    width: 70%;
    padding: 20px;
  }
`;

export const SignInContainer = styled.div`
  font-Size: 11px;
  text-align: center;
`;

export const NormalSignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const GotoSignup = styled.div`
  color: grey;
`;

export const SocialSignInContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: 23px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  padding: 14px 25px;
  cursor: pointer;
`;

export const Social = styled.img`
  width: 20px;
`;

export const Line = styled.div`
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px;
`;