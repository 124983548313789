import styled from "styled-components"

export const Body = styled.div`
  padding: 25px;
`

export const ScoreArea = styled.div`
  display: flex;
  justify-content: flex-end;
  transform: translateY(-25%);
`

export const QuestionArea = styled.div`
  display: flex;
  justify-content: center;
`

export const AnswerArea = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 45%));
  justify-content: center;
  row-gap: 20px;
  column-gap: 30px;
`