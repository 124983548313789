import styled from 'styled-components';

export const FileUploaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const FileUploaderWrapper = styled.div`
  cursor: pointer;
  width: 150px;
  height: 105px;
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  color: black;

  &:hover {
    background-color: #ffdea3
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;
  
export const DragNDropSpace = styled.div`
  font-size: 12px;
  display: flex;
  color: #b4b4b4;
  flex-direction: column;
  align-items: center;
`;

export const DragNDropIcon = styled.div`
  margin-bottom: 6px;
`;