import { DeckModalContainer, DeckModalButtonContainer, Button, InputContainer } from "./DeckModalStyles";
import React, { useState } from "react";
import { getDeckList } from "../../../../Redux/deck/deckThunk";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import Popup from 'ui/popup/Popup';
import {Input} from 'ui/input/InputStyles';
import {Colors} from 'theme';
import { createDeck, updateDeck } from "api/decks";
import { useNavigate } from "react-router-dom";
import { setLocation } from "Redux/location/locationSlice";
import { DeckInterface } from 'interface';
import { setIsLoading } from "Redux/util/utilSlice";
import { useTranslation } from "react-i18next";

interface Props {
  openModal: any,
  data?: any,
  action: string
}

const DeckModal = ( {openModal, data, action} : Props ) => {

  const [t] = useTranslation('deckList')
  const [commonT] = useTranslation('common')
  const navigate = useNavigate(); 
  const [name, setName] = useState<string>(data ? data.name : '');
  const [description, setDescription] = useState<string>(data ? data.description : '');
  const [nameValid, setNameValid] = useState<boolean>(true);
  const [descriptionValid, setDescriptionValid] = useState<boolean>(true);

  const isLoading = useAppSelector((state) => state.reducer.utils.isLoading)
  
  const modalHandler = (event : React.MouseEvent) => {
    openModal('deck');
  };
  
  const nameInput = (event : React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 50) {
      setNameValid(true);
      setName(event.target.value);
    } else {
      setNameValid(false);
    }
  };
  
  const descriptionInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 100) {
      setDescriptionValid(true);
      setDescription(event.target.value);
    } else {
      setDescriptionValid(false);
    }
  };
  
  const dispatch = useAppDispatch();

  const submitHandler = async () => {
    dispatch(setIsLoading(true))
    if (action === 'edit') {
      const obj : DeckInterface = {
        name: name,
        description: description,
        id: data.id,
      };
      await updateDeck(obj);
      await dispatch(getDeckList({})).unwrap();
      openModal();
    } else if (action === 'create') {
      const obj : DeckInterface = {
        name: name,
        description: description,
      };
      const res = await createDeck(obj);
      dispatch(setLocation({
        currentPage: 'dashboard',
        selectedDeck: res?.data,}))
      navigate(`/deck/${res?.data.id}`)
    }
    dispatch(setIsLoading(false))
  };

  return (
    <Popup clickHandler={modalHandler}>
      <DeckModalContainer>
        <div style={{paddingBottom: '28px'}}>
          <InputContainer>
            <div style={{fontSize: '18px', paddingBottom: '28px'}}>{action === 'create' ? t('Create deck') : t('Edit deck')}</div>
            <div style={{fontSize: '13px', color: `${Colors.primary2}`}}>{t('Name')}</div>
            <Input onChange={nameInput} value={name} />
            <div>
            {nameValid ? null : t('Name cannot exceed 50 characters.')}
            </div>
          </InputContainer>
          <InputContainer>
            <div style={{fontSize: '13px', color: `${Colors.primary2}`}}>{t('Description')}</div>
            <Input onChange={descriptionInput} value={description} placeholder={'Optional'} />
            {descriptionValid ? null : t('Description cannot exceed 100 characters.')}
          </InputContainer>
        </div>
        <DeckModalButtonContainer>
          <Button color={Colors.cancel} onClick={modalHandler}>{commonT('Cancel')}</Button>
          <Button 
            color={Colors.confirm} 
            onClick={submitHandler} 
            disabled={name.length > 0 && nameValid && descriptionValid && !isLoading ? false : true}
          >
            {commonT('Save')}
          </Button>
        </DeckModalButtonContainer>
      </DeckModalContainer>
    </Popup>
  )
}

export default DeckModal;