import { useState } from 'react';
import { 
  QuizSettingsContainer,
  QuizSettingsWrapper,
  ModalTitle,
  ParameterName,
  ParameterWrapper,
  SliderWrapper,
  RadioWrapper,
  ErorrMsgWrapper,
  DisableWrapper,
} from './QuizSettingsStyles';
import Popup from 'ui/popup/Popup';
import { QuizButton } from 'ui/button/ButtonStyles';
import { Input } from 'ui/input/InputStyles';
import TagList from 'ui/tagList/TagList';
import { createQuiz } from 'api/quiz';
import { useAppDispatch, useAppSelector } from 'Redux/hooks';
import { setData, setQuizSettings } from 'Redux/quiz/quizSlice';
import { Slider } from 'ui/slider/silder';
import Radio from 'ui/radio/Radio';
import { setIsLoading } from 'Redux/util/utilSlice';
import { useTranslation } from 'react-i18next';
import FlagSelector from 'Components/FlashCard/components/flagSelector/FlagSelector';

type Props = {
  close?: () => void,
  moveOn?: () => void,
  display: boolean,
}

const QuizSettings = ({close, moveOn, display = true}: Props) => {

  const [t] = useTranslation('quiz')
  const location = useAppSelector((state) => state.reducer.location);
  const maxCardNum = location.selectedDeck.activeCardCount;
  const [total, setTotal] = useState<number>(maxCardNum);
  const [tag, setTag] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState<string|undefined>(undefined);
  const dispatch = useAppDispatch();
  const [selectCardBy, setSelectCardBy] = useState<number>(0)
  const [showCardBy, setShowCardBy] = useState<number>(0)
  const [language, setLanguage] = useState('')

  const startQuiz = async () => {
    dispatch(setIsLoading(true))
    const response = await createQuiz({
      deckId: location.selectedDeck.id,
      questionCount: total,
      tagColors: tag.length > 0 ? tag.join(): undefined,
    })
    let side: string = 'random'
    if (showCardBy === 1) side = 'front'
    if (showCardBy === 2) side = 'back'
    if (showCardBy === 3) side = language
    dispatch(setQuizSettings({showCardBy: side}))
    if (response.statusCode === 400) {
      setErrorMsg(t('Not enough cards.'))
    } else {
      if (response) dispatch(setData({data: response.data}));
      if (moveOn) moveOn();
    }
    dispatch(setIsLoading(false))
  }

  const addColor = (color: string) => {
    let _tag: string[] = []
    if (tag.includes(color)) {
      _tag = [...tag]
      const index = _tag.indexOf(color)
      _tag.splice(index, 1)
    }else {
      _tag = [...tag, color]
    }
    setTag(_tag)
  }

  const tagColorArr = location.selectedDeck?.tagColors ? location.selectedDeck.tagColors.map((el:any) => el.hexCode) : []

  const handleShowCardBy = (i: number) => {
    if (i !== 3 && language !== '') setLanguage('')
    if (i === 3) setLanguage(location.selectedDeck.languages[0].isoCode)
    setShowCardBy(i)
  }

  const handleSelectCardBy = (i: number) => {
    if (i !== 1 && tag.length > 0) setTag([])
    setSelectCardBy(i)
  }

  return (
    <Popup display={display} clickHandler={close}>
      <QuizSettingsContainer>
        <QuizSettingsWrapper>
          <ModalTitle>{t('Quiz settings')}</ModalTitle>

          <ParameterWrapper>
            <ParameterName>{t('Select cards by')}</ParameterName>
            <Radio 
             name="selectCardBy"
             label={t('Random selection')}
             id={0}
             value={0}
             selected={selectCardBy}
             onClick={() => handleSelectCardBy(0)}
            />
            <Radio 
             name="selectCardBy"
             label={t('Color tag')}
             id={1}
             value={1}
             selected={selectCardBy}
             onClick={() => handleSelectCardBy(1)}
            />
            <DisableWrapper isSelected={selectCardBy === 1}>
              <TagList
                onClick={addColor}
                tagColor={tag}
                abledList={tagColorArr}
              />
            </DisableWrapper>
          </ParameterWrapper>

          <ParameterWrapper>
            <ParameterName>{t('Show cards by')}</ParameterName>
            <Radio 
             name="showCardBy"
             label={t('Random')}
             id={0}
             value={0}
             selected={showCardBy}
             onClick={() => handleShowCardBy(0)}
            />
            <Radio 
             name="showCardBy"
             label={t('By question')}
             id={1}
             value={1}
             selected={showCardBy}
             onClick={() => handleShowCardBy(1)}
            />
            <Radio 
             name="showCardBy"
             label={t('By answer')}
             id={2}
             value={2}
             selected={showCardBy}
             onClick={() => handleShowCardBy(2)}
            />
            <Radio 
             name="showCardBy"
             label={t('Fill in the blank')}
             id={3}
             value={3}
             selected={showCardBy}
             onClick={() => handleShowCardBy(3)}
            />
            <DisableWrapper isSelected={showCardBy===3}>
              <FlagSelector 
                selected={language}
                onClick={setLanguage}
                enableList={location.selectedDeck.languages}
              />
            </DisableWrapper>
          </ParameterWrapper>

          <ParameterWrapper>
            <ParameterName>{t('Number of questions')}</ParameterName>
            <SliderWrapper><Slider min={1} max={maxCardNum} value={total} setValue={(e:number) => setTotal(e)}/></SliderWrapper>
            <Input value={total} readOnly />
          </ParameterWrapper>
          
        </QuizSettingsWrapper>
        <ErorrMsgWrapper>
          {errorMsg}
        </ErorrMsgWrapper>
        <QuizButton onClick={startQuiz}>
          {t('Start Quiz!')}
        </QuizButton>
      </QuizSettingsContainer>
    </Popup>
  );
}

export default QuizSettings;