import styled from 'styled-components';

export const LastOpen = styled.div`
  text-align: end;
`;

export const MenuContainer = styled.span`
  border-radius: 10px;
  position: absolute;
  right: 3%;
  top: 14%;
  opacity: 1;
  
  &:hover {
    opacity: 0.8;
  }
`;