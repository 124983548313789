import Popup from 'ui/popup/Popup';
import { DoubleCheckButtonContainer, Button } from '../DoubleCheck/DoubleCheckStyles';
import { Colors } from 'theme';
import { useAppSelector, useAppDispatch } from 'Redux/hooks';
import { signOutUser } from "Redux/user/userThunk";
import { resetUserState } from "Redux/user/userSlice";
import { reset } from "Redux/deck/deckSlice";
import { auth } from "../../firebase-config";
import { useTranslation } from 'react-i18next';

type Props = {
  closeModal: any,
}

export const SignOutModal = ({closeModal}:Props) => {

  const [t] = useTranslation('mypage')
  const [common_t] = useTranslation('common')

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.reducer.users);
  const handleSingOut = async () => {
    dispatch(signOutUser({auth: auth, userId: user.userId, anonymous: user.anonymous}))
    .then( async (res) => {
      if (res.payload) {
        dispatch(resetUserState());
        dispatch(reset());
      }
    })
  }

  return (
    <Popup clickHandler={closeModal}>
      <div style={{fontSize: '18px', paddingBottom: '28px'}}>{t('Sign out')}</div>
      {user.anonymous && 
        <div style={{fontSize: '14px', paddingBottom: '28px'}}>
          {t('Singing out from anonymous user will delete all data.')}
        </div>
      }
      <div style={{fontSize: '14px', paddingBottom: '28px'}}>
          {t('Do you really want to sign out?')}
      </div>
      <DoubleCheckButtonContainer>
        <Button color={Colors.cancel} onClick={closeModal}>{common_t('No')}</Button>
        <Button color={Colors.confirm} onClick={handleSingOut}>{common_t('Yes')}</Button>
      </DoubleCheckButtonContainer>
    </Popup>
  )
}

export default SignOutModal;