import { auth, provider } from "firebase-config";
import { createUser, getUser } from "api/users";
import { signInWithPopup } from "firebase/auth";

const checkAndCreateUser = async () => {
  const userInfo = await getUser();
  if (!userInfo.data) await createUser();

  const res = await getUser();
  return res?.data;
}

export const googleSignIn = async () => {
  try {
    const res = await signInWithPopup(auth, provider)

    if (res) {
      const userInfo = await checkAndCreateUser();

      return userInfo
    }
     
  } catch (e) {
    console.error(e);
  }
}