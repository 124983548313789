import styled from 'styled-components';
import { Colors } from 'theme';

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  min-height: 30px;
  cursor: pointer;
`;

export const Input = styled.input`
  display: none;
`;

export const DisplayInputWrapper = styled.span`
  display: inline-flex;
  padding: 2px;
  border: 2px solid ${Colors.primary2};
  border-radius: 50%;
  width: 11px;
  height: 11px;
  justify-content: center;
  align-items: center;
`

export const DisplayInput = styled.span<{checked: boolean}>`
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${props => props.checked ? Colors.primary2 : '#fff'};
`

export const Label = styled.label`
  font-size: 12px;
`