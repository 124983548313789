import styled from "styled-components";

export const TopMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
  padding: 1rem 2rem 0 0;
  margin: 0 0 1.5rem 65px;
`;

export const LeftItems = styled.div`
  font-size: 27px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const RigthItems = styled.div`
  display: flex;
  align-items: center;
`;
