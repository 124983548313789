import { FlashCardContainer, FlashCardWrapper, FrontSide, MenuContainer } from "./CardStyles";
import { useState, useEffect, useRef } from "react";
import { FaEllipsisV } from "react-icons/fa";
import InnerMenu from "Components/FlashCard/components/InnerMenu";
import CardModal from '../../Components/FlashCard/CardModal';
import DoubleCheck from '../../Components/DoubleCheck/DoubleCheck';
import { CardInterface } from "interface";

interface Props {
  data : CardInterface,
  clickHandler: any,
}

const Card = ( {data, clickHandler}: Props) => {
  //InnerMenu component
  const [openInnerMenu, SetOpenInnerMenu] = useState<boolean>(false);
  const innerMenuHandler = (event: React.MouseEvent) => {
    SetOpenInnerMenu(!openInnerMenu);
    event.preventDefault();
    event.stopPropagation();
  }

  //Outside click detecting
  const ref = useRef<any>();
  useEffect(()=>{
    const handleClickOutside=(event: any)=>{
      if (ref.current){
        if (!ref.current.contains(event.target)) {
          SetOpenInnerMenu(false);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  //Edit modal component
  const [modal, setModal] = useState<boolean>(false);
  const openModal = () => {
    setModal(!modal);
  };

  //Delete modal component
  const [doubleCheck, setDoubleCheck] = useState<boolean>(false);
  const openDoubleCheck = () => {
    setDoubleCheck(!doubleCheck);
  }

  return (
    <FlashCardContainer>
      <FlashCardWrapper color={data?.tagColor?.hexCode}>
        <FrontSide onClick={() => clickHandler(data)}>
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <div style={{fontSize: '19px', fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              {data.front}
            </div>
            <MenuContainer ref={ref} className={openInnerMenu ? 'focus' : ''}>
              <FaEllipsisV size={20} onClick={innerMenuHandler}/>
              {openInnerMenu ? 
                <InnerMenu innerMenuHandler={innerMenuHandler} openModal={openModal} openDoubleCheck={openDoubleCheck}/> 
              : null}
            </MenuContainer>
          </div>
          <div style={{marginTop: '4px',fontSize: '14px', wordWrap: 'break-word', width: '100%', height: '90px', overflow: 'hidden'}}>
            {data.back}
          </div>
        </FrontSide>
      </FlashCardWrapper>
      {doubleCheck ? <DoubleCheck openDoubleCheck={openDoubleCheck} data={data} item='cards'/> : null}
      {modal ? <CardModal openModal={openModal} data={data} action='edit'/> : null}
    </FlashCardContainer>
  )
};

export default Card;