import { 
  Body, 
  ScoreArea, 
  QuestionArea, 
  AnswerArea,
  ProgressBarWrapper,
  ScoreWrapper,
  AnswerAreaSection,
} from "./QuizMobile.styles"
import Progressbar from "Components/Quiz/quiz/components/Progressbar"
import Score from "Components/Quiz/quiz/components/Score"
import QuestionCard from "Components/Quiz/quiz/components/QuestionCard"
import AnswerCardList from "Components/Quiz/quiz/components/AnswerCardList"
import { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { getQuiz, updateQuiz, generateContentQuiz } from "api/quiz";
import { updateTotalAnsweredCorrect, updateTotalAnsweredIncorrect, setData, updateQuestion, saveShownSideofQuiz } from "Redux/quiz/quizSlice";
import { setIsLoading } from "Redux/util/utilSlice";
import { useNavigate } from "react-router-dom"
import { Colors } from "theme"

const QuizMobile = () => {

  const cards = useAppSelector((state) => state.reducer.cards)
  const quiz = useAppSelector((state) => state.reducer.quiz.data);
  const quizSettings = useAppSelector((state) => state.reducer.quiz.quizSettings)
  const dispatch = useAppDispatch()
  const [quizNo, setQuizNo] = useState<number>(0);
  const navigate = useNavigate()
  const [quizSide, setQuizSide] = useState('front')

  const submit = async (selectedAnswer: any) => {
    
    const quizId = quiz.id;
    const questionId = quiz.questions[quizNo].id;
    const cardSide = getSide(quiz.questions[quizNo].cardRef.id)

    updateQuiz({
      quizId: quizId,
      questionId: questionId,
      data: 
      {
        ...quiz.questions[quizNo],
        userChoiceCardRef: {
          id: selectedAnswer.cardRef.id
        },
        shownAt: new Date(),
        shownSide: cardSide,
      },
    })
    
    if (selectedAnswer.isCorrectChoice) {
      dispatch(updateTotalAnsweredCorrect())
    } else {
      dispatch(updateTotalAnsweredIncorrect())
    }

    dispatch(saveShownSideofQuiz(quizSide))
    
    if (quiz.questions.length <= quizNo +1) {
      dispatch(setIsLoading(true))
      const res = await getQuiz({quizId: quizId})
      dispatch(setData({data:res?.data}))
      dispatch(setIsLoading(false))
      return navigate('/quizResult')
    };
    await makeQuiz(quizNo+1)
    setQuizNo((prevQuizNo) => prevQuizNo + 1);
  }

  useEffect(() => {
    makeQuiz(0)
  },[])
  
  const makeQuiz = async (quizNo : number) => {
    if (!quiz) return
    dispatch(setIsLoading(true))
    const quizId = quiz.id;
    const questionId = quiz.questions[quizNo].id;
    const showCardBy = quizSettings.showCardBy

    if (showCardBy === 'front') {
      setQuizSide('front')
      dispatch(updateQuestion({questionNo: quizNo, question: quiz.questions[quizNo]}))
    } else if (showCardBy === 'back') {
      setQuizSide('back')
      dispatch(updateQuestion({questionNo: quizNo, question: quiz.questions[quizNo]}))
    } else if (showCardBy === 'random') {
      const side = getSideRandomly()
      setQuizSide(side)
      dispatch(updateQuestion({questionNo: quizNo, question: quiz.questions[quizNo]}))
    } else {
      const cardSide = getSide(quiz.questions[quizNo].cardRef.id)
      const res = await generateContentQuiz({quizId, questionId, cardSide: cardSide})
      if (!res) return
      dispatch(updateQuestion({questionNo: quizNo, question: res.data}))
    }

    dispatch(setIsLoading(false))
  }

  const getSide = (cardRef: string) => {
    return checkRefCardLang(cardRef)
  }

  const checkRefCardLang = (cardRef: string) => {
    const card = cards.find((card, i) => card.id === cardRef)
    if (quizSettings.showCardBy === card?.frontLanguage.isoCode) return 'front'
    if (quizSettings.showCardBy === card?.backLanguage.isoCode) return 'back'
    return 'front'
  }

  const getSideRandomly = () : string => {
    if (Math.random() > 0.5) return 'front'
    else return 'back'
  }

  if (!quiz) return <></>

  return (
    <>
      <Body>

        <ScoreArea>
          <ProgressBarWrapper>
            <Progressbar />
          </ProgressBarWrapper>
          <ScoreWrapper>
            <Score />
          </ScoreWrapper>
        </ScoreArea>

        <QuestionArea>
          <QuestionCard 
            quizNo={quizNo}
            quizSide={quizSide}
            sx={{
              minHeight: '200px',
              fontWeight: 300,
              fontSize: '20px',
            }}
          />
        </QuestionArea>

      </Body>

      <AnswerAreaSection>
        <AnswerArea>
          <AnswerCardList 
            quizNo={quizNo}
            quiz={quiz}
            quizSide={quizSide}
            onClick={(question: any) => submit(question)}
            sx={{
              fontSize: '16px',
              minWidth: '170px',
              width: '100%',
              backgroundColor: Colors.primary2,
              border: 'none',
            }}
          />
        </AnswerArea>
      </AnswerAreaSection>
    </>
  )
}

export default QuizMobile