import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const ArrowWrapper = styled.div`
  display: flex;
  gap: 20px;
  position: relative;
  width: 90%;
  justify-content: center;
  align-items: center;
`

export const Arrow = styled.div<{ direction: string }>`
  width: 100%;
  flex-grow: 1;
  height: 60px;
  border-radius: 10px;
  border: 1.5px solid #585858;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => props.direction === 'left' ? 'scaleX(-1)' : ''};
`

export const ESCWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ESC = styled.div`
  color: #585858;
  width: 100px;
  height: 30px;
  font-size: 18px;
  border-radius: 10px;
  border: 1.5px solid #585858;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`