import styled from 'styled-components';

export const DeckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 17px;
  position: relative;
  width: 310px;
`;