import Ui from "./ui/Ui"
import styled from 'styled-components';
import { CardInterface } from "interface";
import { useState, useEffect, useLayoutEffect } from 'react'
import Card from "./card/Card";
import { gsap } from 'gsap';

const Container = styled.div`
  position: absolute;
  background-color: #262626;
  width: 100%;
  height: 100%;
`

interface Props {
  cardList: CardInterface[],
  selectedCard: number,
  close: () => void,
}

const DeskTopCard = ({
  cardList=[],
  selectedCard,
  close,
}: Props) => {

  const [screenSize, setScreenSize] = useState({width: window.innerWidth, height: window.innerHeight});
  const [idx, setIdx] = useState(selectedCard);
  const [action, setAction] = useState<string>('neutral');
  const [shownside, setShownside] = useState('front');
  
  useEffect(() => {
    const gap = screenSize.width/cardList.length/4
    const element = document.getElementById(`${cardList[idx].id}`)
    const [offsetWidth, offsetHeight] = getOffsets(element||null)

    if (action === 'forward') {
      gsap.to(element, 
        {
          x: window.innerWidth / 2 - offsetWidth / 2,
          y: window.innerHeight / 2 - offsetHeight / 2,
          duration: 0.5,
          ease: 'expo',
        }
      )
      if (idx+1 <= cardList.length -1) {
        gsap.to(document.getElementById(`${cardList[idx+1].id}`), 
          {
            x: window.innerWidth - offsetWidth - (gap * (cardList.length - 1 - (idx+1))) -30,
            y: window.innerHeight - offsetHeight -30,
            duration: 0.5,
            ease: 'expo',
          }
        )
      }
    }

    if (action === 'backward') {
      gsap.to(element, 
        {
          x: window.innerWidth / 2 - offsetWidth / 2,
          y: window.innerHeight / 2 - offsetHeight / 2,
          duration: 0.5,
          ease: 'expo',
        }
      )
      if (idx-1 >= 0) {
        gsap.to(document.getElementById(`${cardList[idx-1].id}`), 
          {
            x: gap * (idx-1) + 30, 
            y: 0+30,
            duration: 0.5,
            ease: 'expo',
          }
        )
      }
    }
    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  },[idx])

  useLayoutEffect(() => {
    const resizeListener = () => setScreenSize({width: window.innerWidth, height: window.innerHeight})
    window.addEventListener('resize', resizeListener)

    const gap = screenSize.width/cardList.length/4
    cardList.forEach((el, i) => {
      const element = document.getElementById(`${el.id}`)
      const [offsetWidth, offsetHeight] = getOffsets(element||null)
      if (cardList[idx].id === el.id) {
        const [offsetWidth, offsetHeight] = getOffsets(element || null)
        gsap.to(element, 
          {
            x: window.innerWidth/2 - offsetWidth / 2,
            y: window.innerHeight/2 - offsetHeight / 2,
            duration: 0.21 * i,
            ease: 'expo',
          }
        )
      } else if (i < idx) {
        gsap.to(document.getElementById(`${cardList[i].id}`), 
          {
            x: gap * i + 30, 
            y: 30,
            duration: 0.21 * i,
            ease: 'expo',
          }
        )
      } else if (i > idx) {
        gsap.to(document.getElementById(`${cardList[i].id}`), 
          {
            x: window.innerWidth - offsetWidth - (gap * (cardList.length - (i+1))) -30,
            y: window.innerHeight - offsetHeight -30,
            duration: 0.5,
            ease: 'expo',
          }
        )
      }
    })

    return () => window.removeEventListener('reset', resizeListener)
  },[window.innerWidth])

  const handleClick = (target: string) => {
    if (target === 'arrow-left') {
      if (idx <= 0) return
      setShownside('front')
      setAction('forward')
      setIdx((prev) => prev-1)
      return
    }
    if (target === 'arrow-right') {
      if (idx >= cardList.length -1) return
      setShownside('front')
      setAction('backward')
      setIdx((prev) => prev+1)
      return
    }
    if (target === 'esc') {
      return close()
    }
    if (target === 'space') {
      setShownside((prev) => {
        if (prev === 'front') return 'back'
        if (prev === 'back') return 'front'
        return 'front';
      })
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ') {
      setShownside((prev) => {
        if (prev === 'front') return 'back'
        if (prev === 'back') return 'front'
        return 'front';
      })
    }

    if (e.key === 'ArrowLeft') {
      if (idx <= 0) return
      setShownside('front')
      setAction('forward')
      setIdx((prev) => prev-1)
      return
    }

    if (e.key === 'ArrowRight') {
      if (idx >= cardList.length -1) return
      setShownside('front')
      setAction('backward')
      setIdx((prev) => prev+1)
      return
    }

    if (e.key === 'Escape') {
      return close()
    }
  }

  const getOffsets = (element: Element | null) => {
    let offsetWidth = 0 
    let offsetHeight = 0
    if (element){
      const elementNodes = Array.from(element.children);
      elementNodes.forEach((el) => {
        if (el instanceof HTMLElement) {
          offsetWidth = el.offsetWidth
          offsetHeight = el.offsetHeight
        }
      })
    }

    return [offsetWidth, offsetHeight]
  }

  return (
    <Container>
      <Ui screenSize={screenSize} onClick={handleClick} />
      {cardList.map((el, i) => (
        <div 
          id={`${el.id}`} 
          key={el.id}
        >
          <Card  
            isSelected={i === idx}
            front={el.front}
            back={el.back}
            color={el?.tagColor?.hexCode}
            shownSide={ i === idx ? shownside : 'front'}
          />
        </div>
      ))}
    </Container>
  )
}

export default DeskTopCard