import { TagContainer, TagWrapper } from "./TagStyles";
import {FaCheck} from 'react-icons/fa';

interface Props {
  color: string,
  selected: string | string[],
  callBack?: () => void,
  isAble?: boolean,
}

const Tag = ({ color, selected, callBack, isAble = true } : Props) => {

  const type = typeof(selected) === 'string' ? 'string' : 'array'

  return (
    <TagContainer onClick={isAble ? callBack : () => {}} className={isAble ? '':'disabled'}>
      {type === 'string' ?
        <TagWrapper className={color === selected ? 'selected': ''} color={color}>
          {color === selected && <FaCheck color="#19ce60" size='15px'/>}
        </TagWrapper>
      :
        <TagWrapper className={selected.includes(color) ? 'selected': ''} color={color}>
          {selected.includes(color) && <FaCheck color="#19ce60" size='15px'/>}
        </TagWrapper>
      }
    </TagContainer>
  )
}

export default Tag;