import styled from "styled-components";
import GoodJob from 'Img/goodjob.svg';
import Correct from 'Img/correct.svg';
import Wrong from 'Img/wrong.svg';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  background-color: #f4ab0b;
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 70px;
  padding: 40px 0px;
`;

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  gap: 50px;
  border-radius: 10px;
  min-width: 300px;
  width: 40%;
  min-height: 100px;
  padding: 20px 20px;
`

const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const Score = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;

  .score {
    font-size: 36px;
    font-weight: bold;
  }
`

const ResultBoard = () => {

  const quiz = useAppSelector((state) => state.reducer.quiz.data);
  const [t] = useTranslation('quiz')

  return (
    <Container>
      <img width="300px" src={GoodJob} alt="goodjob" />
      <Wrapper>
        <ScoreWrapper>
          <img width='60px' src={Correct} alt="correct" />
          <Score>
            <div className="score">{quiz.totalAnsweredCorrect}</div>
            <div>{t('Correct')}</div>
          </Score>
        </ScoreWrapper>
        <ScoreWrapper>
          <img width='60px' src={Wrong} alt="wrong" />
          <Score>
            <div className="score">{quiz.totalAnsweredIncorrect}</div>
            <div>{t('Incorrect')}</div>
          </Score>
        </ScoreWrapper>
      </Wrapper>
    </Container>
  )
}

export default ResultBoard;