import styled from "styled-components";
import AddDeck from "../Components/DeckList/components/AddDeck/AddDeck";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from '../Redux/hooks';
import { getDeckList } from "../Redux/deck/deckThunk";
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth"; 
import { reset } from "../Redux/deck/deckSlice";
import { setLocation } from "../Redux/location/locationSlice";
import FileUploader from "../Components/FileUploader/FileUploader";
import Decks from "Components/DeckList/Decks";
import { setIsLoading } from "Redux/util/utilSlice";
import FloatingButton from "ui/floatingButton/FloatingButton";
import Icon from "Img/ai.svg";
import AiDeckModal from "Components/DeckList/components/AiDeckModal/AiDeckModal";

const DeckContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 65px;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 0px;
  }
`;

const DeckList = () => {

  const user = useAppSelector((state) => state.reducer.users);
  const search = useAppSelector((state) => state.reducer.search);
  const dispatch = useAppDispatch();

  const [openAideckModal, setOpenAideckModal] = useState(false);

  const fetchDeckList = async () => {
    dispatch(setIsLoading(true))
    await dispatch(getDeckList({sortField:search.sort, sortDirection:search.ascDesc,})).unwrap();
    dispatch(setIsLoading(false))
  };

  useEffect(() => {
    onAuthStateChanged(auth, (res: any) => {
      //Fetch Decklist
      if (res && user.userId !== '') {
        fetchDeckList();
      } else {
        dispatch(reset());
      }
    });
  },[search])

  useEffect(() => {
    if (!user.signin) window.location.href='/'
    dispatch(setLocation({currentPage:'decks', selectedDeck: {}}));
  }, [user]);

  return (
    <DeckContainer>
      <Decks />
      <div style={{margin: '17px', display: 'flex'}}>
        <AddDeck />
        <FileUploader/>
      </div>

      {openAideckModal ? 
        <AiDeckModal 
          closeModal={() => setOpenAideckModal(false)}
        />
      :null}

      <FloatingButton 
        img={Icon}
        onClick={() => setOpenAideckModal(true)}
      />
    </DeckContainer>
  )
}

export default DeckList;