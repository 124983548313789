import BackarrowTail from "Img/backarrowtail.svg";
import { FaTrashAlt } from 'react-icons/fa';
import { useAppSelector } from 'Redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import DoubleCheck from 'Components/DoubleCheck/DoubleCheck';
import { Container, Img, NameContainer, CardCounter } from "./DeckNameStyles";

const DeckName = () => {

  const location = useAppSelector((state) => state.reducer.location)
  const cards = useAppSelector((state) => state.reducer.cards)
  const navigate = useNavigate()

  const [doubleCheck, setDoubleCheck] = useState<boolean>(false);

  const deleteDeck = () => {
    setDoubleCheck(!doubleCheck)
  }

  return (
    <>
      <Container>
        <Img 
          src={BackarrowTail} 
          alt="back-arrow"
          onClick={() => navigate(-1)}
        />
        <NameContainer>
          {location?.selectedDeck.name}
        </NameContainer>
        <FaTrashAlt size='20px' style={{marginLeft: '12px', cursor: 'pointer'}} onClick={deleteDeck}/>
        <CardCounter>
          {location?.selectedDeck.activeCardCount > 0 ?
            `${cards.length} / ${location?.selectedDeck.activeCardCount} Cards`
          :null}
        </CardCounter>
      </Container>
      {doubleCheck ?
        <DoubleCheck 
          openDoubleCheck={deleteDeck} 
          data={location.selectedDeck} 
          item='decks'
        />
      :null}
    </>
  )
}

export default DeckName