import styled from "styled-components";
import Tag from "ui/tagList/Tag";
import { TagColors } from "theme";

export const TagListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type Props = {
  onClick: (string: string) => void,
  tagColor: string[],
  abledList?: string[]
}

const TagList = ({onClick, tagColor, abledList}: Props) => {
  return (
    <TagListContainer>
      {TagColors.map((color, i) => (
        <Tag
          key={i}
          color={color} 
          selected={tagColor}
          isAble={abledList ? abledList.includes(color) : true}
          callBack={()=> onClick(TagColors[i])}
        />
      ))}
    </TagListContainer>
  )
}

export default TagList;