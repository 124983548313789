import styled from "styled-components";

export const QuizButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  border-radius: 10px;
  margin-left: 10px;

  svg {
    &:hover {
      background-color: #ffdea3;
      border-radius: 10px;
    }
  }

  cursor: pointer;
`;