import styled from "styled-components";

export const Container = styled.div`
  display: flex; 
  align-items: center;
`

export const Img = styled.img`
  width: 18px;
  margin-right: 15px; 
  cursor:pointer; 
  border: 1px solid #000; 
  border-radius: 50%; 
  padding: 9px;
`

export const NameContainer = styled.div`
  font-size: 27px; 
  max-width: 360px; 
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
`

export const CardCounter = styled.div`
  margin-left: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
`