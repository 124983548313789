import QuizResultMobile from "Components/QuizResultMobile/QuizResultMobile";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { setLocation } from "Redux/location/locationSlice";
import { useEffect } from "react";

const QuizResult = () => {

  const dispatch = useAppDispatch()
  const location = useAppSelector((state) => state.reducer.location)

  useEffect(() => {
    //Change current location state
    dispatch(setLocation({currentPage:'quizResult', selectedDeck: location.selectedDeck}));
  }, []);

  return (
    <QuizResultMobile />
  )
}

export default QuizResult