import { useState, useEffect } from 'react';
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import MobileMenu from "./MobileMenu/MobileMenu";

const TopMenu = () => {

  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);


  return (
    <div>
      {matches ?
        <DesktopMenu />
      :
        <MobileMenu />
      }
    </div>
  )
}

export default TopMenu;