import styled from "styled-components";
import { Colors } from "theme";

export const Container = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  gap: 8px;  
`

export const Flag = styled.img<{selected: boolean}>`
  width: 16px;
  cursor: pointer;
  transition: scale 0.3s ease;
  border-radius: 50%;
  border: ${props => props.selected ? `1px solid ${Colors.primary2}` : '1px solid white'};

  scale: ${props => props.selected ? 'calc(150%)' : ''};

  :hover {
    scale: calc(150%);
  }

  &.unabled {
    cursor: unset;
    opacity: 0.3;
    pointer-events: none;
  }
`