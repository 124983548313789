import styled from "styled-components";
import Correct from 'Img/correct.svg';
import Wrong from 'Img/wrong.svg';
import { useAppSelector } from "Redux/hooks";

const Container = styled.div`
  display: flex;
  gap: 15px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    font-size: 18px;
    font-weight: bold;
  }
`

const Score = () => {

  const quiz = useAppSelector((state) => state.reducer.quiz.data);

  return (
    <Container>
      <Wrapper>
        <img width='30px' src={Correct} alt="correct" />
        <div>{quiz.totalAnsweredCorrect}</div>
      </Wrapper>
      <Wrapper>
        <img width='30px' src={Wrong} alt="wrong" />
        <div>{quiz.totalAnsweredIncorrect}</div>
      </Wrapper>
    </Container>
  )
}

export default Score;