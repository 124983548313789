import { useAppSelector } from 'Redux/hooks';
import './preloader.css'
import styled from 'styled-components';

const Container = styled.div<{isDisplay: boolean}>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isDisplay? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  z-index: 100;
`

const Preloader = () => {

  const isLoading = useAppSelector(state => state.reducer.utils.isLoading)

  return (
    <Container isDisplay={Boolean(isLoading)}>
      <div className="pl1">
        <div className="pl1__a"></div>
        <div className="pl1__b"></div>
        <div className="pl1__c"></div>
      </div>
    </Container>
  )
};

export default Preloader;