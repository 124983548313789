export const Colors = {
  primary1: '#000000',
  primary2: '#F4AB29',
  white: '#FFFFFF',
  hover: '#FFDEA3',
  confirm: '#203772',
  cancel: '#E53E3E',
  gray1: '#E2E2E2',
  gray2: '#B4B4B4',
  disable: '#E6E6E6',
}

export const TagColors = [
  '#ff6262', 
  '#fdbe88',
  '#f8e8b2',
  '#73d69d', 
  '#3aa1ff', 
  '#5171f7',
  '#c082e6',
  '#e2e2e2',
  '#696969'
]