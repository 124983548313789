import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import decksReducer from './deck/deckSlice';
import cardsReducer from './card/cardSlice';
import usersReducer from './user/userSlice';
import searchReducer from './search/searchSlice';
import locationReducer from './location/locationSlice';
import quizReducer from './quiz/quizSlice';
import utilsReducer from './util/utilSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['users', 'location']
}

const languagePersisConfig = {
  key: 'utils',
  storage,
  whitelist: ['frontLanguage', 'backLanguage']
}

const reducers = combineReducers({
  decks: decksReducer,
  cards: cardsReducer,
  users: usersReducer,
  search: searchReducer,
  location: locationReducer,
  quiz: quizReducer,
  utils: persistReducer(languagePersisConfig, utilsReducer)
})

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: {
    reducer: persistedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
})
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;