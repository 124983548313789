import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import icon from "../../Img/profile.png";

interface UserState {
  userId: string,
  email: string,
  img: string,
  name: string,
  signin: boolean,
  anonymous: boolean,
  redirected: boolean,
}

const initialState: UserState = {
  userId: '',
  email: '',
  img: icon,
  name: '',
  signin: false,
  anonymous: false,
  redirected: false,
}

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserState: (state: UserState, action: PayloadAction<any>) => {
      state.userId = action.payload.userId;
      state.email = action.payload.email;
      state.img = action.payload.img;
      state.name = action.payload.name;
      state.signin = action.payload.signin;
      state.anonymous = action.payload.anonymous;
    },
    setRedirectionCondition: (state: UserState, action: PayloadAction<any>) => {
      state.redirected = action.payload.redirected
    },
    resetUserState: () => initialState
  }
})

export const { setUserState, resetUserState, setRedirectionCondition } = users.actions;
export default users.reducer;