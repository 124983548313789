import Popup from 'ui/popup/Popup';
import { useState } from 'react';
import { UploadModalContainer, Lable, Button, Dropdown } from './FileUploaderModalStyles';
import File from 'Img/file.svg';
import { useTranslation } from 'react-i18next';
import FlagSelector from 'Components/FlashCard/components/flagSelector/FlagSelector';
import { useAppSelector } from 'Redux/hooks';
import { useDispatch } from 'react-redux';
import { setFrontLanguage as _setFrontLanguage, setBackLanguage as _setBackLanguage } from 'Redux/util/utilSlice';

type Props = {
  closeModal: any,
  createDeck: any,
  acceptedFiles: any,
}

export const FileUploaderModal = ({
  closeModal, 
  createDeck, 
  acceptedFiles,
}: Props) => {

  const [t] = useTranslation('deckList')
  const [commonT] = useTranslation('common')
  const [questionColumn, setQuestionColumn] = useState();
  const [answerColumn, setAnswerColumn] = useState();
  const dispatch = useDispatch()

  const handleChange = (e: any, target: string) => {
    if (target === 'question') setQuestionColumn(e.target.value);
    if (target === 'answer') setAnswerColumn(e.target.value);
  }
  const defaultBack = useAppSelector((state) => state.reducer.utils.backLanguage)
  const defaultFront = useAppSelector((state) => state.reducer.utils.frontLanguage)

  const [frontLanguage, setFrontLanguage] = useState<string>(defaultFront ? defaultFront : 'en')
  const [backLanguage, setBackLanguage] = useState<string>(defaultBack ? defaultBack : 'en')

  const setLanguage = (frontBack: string, language: string) => {
    if (frontBack === 'front') {
      dispatch(_setFrontLanguage(language))
      setFrontLanguage(language)
    } else {
      dispatch(_setBackLanguage(language))
      setBackLanguage(language)
    }
  }

  return (
    <Popup clickHandler={closeModal}>
      <UploadModalContainer>
        <div style={{fontSize: '18px', paddingBottom: '28px'}}>{t('Create deck')}</div>
        <div style={{display: 'flex', borderRadius: '10px', backgroundColor: '#f2f2f2', padding: '22px'}}>
          <img src={File} style={{marginRight: '6px'}}/> 
          <div style={{fontWeight: 'bold'}}>{t('File Name')} : {acceptedFiles[0].name}</div>
        </div>
        <Lable>{t('Select a question column.')}</Lable>
        <Dropdown onChange={(e) => handleChange(e, 'question')}>
          <option value='null'>Select a column</option>
          <option value={0}>A</option>
          <option value={1}>B</option>
          <option value={2}>C</option>
          <option value={3}>D</option>
          <option value={4}>E</option>
          <option value={5}>F</option>
          <option value={6}>G</option>
          <option value={7}>H</option>
          <option value={8}>I</option>
          <option value={9}>J</option>
          <option value={10}>K</option>
          <option value={11}>L</option>
          <option value={12}>M</option>
          <option value={13}>N</option>
          <option value={14}>O</option>
          <option value={15}>P</option>
          <option value={16}>Q</option>
          <option value={17}>R</option>
          <option value={18}>S</option>
          <option value={19}>T</option>
          <option value={20}>U</option>
          <option value={21}>V</option>
          <option value={22}>W</option>
          <option value={23}>X</option>
          <option value={24}>Y</option>
          <option value={25}>Z</option>
        </Dropdown>
        <FlagSelector 
          selected={frontLanguage}
          onClick={(language: string) => setLanguage('front', language)}
        />
        <Lable>{t('Select an answer column.')}</Lable>
        <Dropdown onChange={(e) => handleChange(e, 'answer')}>
          <option value='null'>Select a column</option>
          <option value={0}>A</option>
          <option value={1}>B</option>
          <option value={2}>C</option>
          <option value={3}>D</option>
          <option value={4}>E</option>
          <option value={5}>F</option>
          <option value={6}>G</option>
          <option value={7}>H</option>
          <option value={8}>I</option>
          <option value={9}>J</option>
          <option value={10}>K</option>
          <option value={11}>L</option>
          <option value={12}>M</option>
          <option value={13}>N</option>
          <option value={14}>O</option>
          <option value={15}>P</option>
          <option value={16}>Q</option>
          <option value={17}>R</option>
          <option value={18}>S</option>
          <option value={19}>T</option>
          <option value={20}>U</option>
          <option value={21}>V</option>
          <option value={22}>W</option>
          <option value={23}>X</option>
          <option value={24}>Y</option>
          <option value={25}>Z</option>
        </Dropdown>
        <FlagSelector 
          selected={backLanguage}
          onClick={(language: string) => setLanguage('back', language)}
        />
        <div style={{marginTop: '40px'}}>
          <Button color='red' onClick={closeModal}>{commonT('Cancel')}</Button>
          <Button 
            disabled={questionColumn && (questionColumn !== 'null') && answerColumn && (answerColumn !== 'null') ? false : true} 
            onClick={() => createDeck(acceptedFiles, questionColumn, answerColumn, frontLanguage, backLanguage)}
          >
            {commonT('Save')}
          </Button>
        </div>
      </UploadModalContainer>
    </Popup>
  )
}