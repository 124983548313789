import { useAppSelector } from "Redux/hooks";
import { Container, Wrapper, CardCounter } from "./MobileFilterStyles"
import Sort from 'Img/sort.svg';

interface Props {
  onClick: () => void
}

const MobileFilter = ({ onClick }:Props) => {

  const selectedDeck = useAppSelector((state) => state.reducer.location.selectedDeck)
  const cards = useAppSelector((state) => state.reducer.cards)

  return (
    <Container>
      <CardCounter>
        {cards.length} / {selectedDeck.activeCardCount} Cards
      </CardCounter>
      <Wrapper onClick={onClick} >
        <img src={Sort} style={{marginRight: '7px'}}/>
        sort·filter
      </Wrapper>
    </Container>
  )
}

export default MobileFilter