import { Container, Space, Arrow, ESC } from "./UiStyles"
import arrow from './arrow.svg'
import arrow_white from './arrow_white.svg'
import { useRef, useLayoutEffect, useState } from "react"

const Ui = ({screenSize, onClick}) => {

  const containerRef = useRef()
  const [uiPosition, setUiPosition] = useState({x: 0, y: 0})
  
  useLayoutEffect(() => {
    setUiPosition({
      x: screenSize.width/2 - containerRef.current.clientWidth/2,
      y: (screenSize.height*2/3) + containerRef.current.clientHeight/2
    })
    
    const spaceElement = document.getElementById('space');
    const arrowLeftElement = document.getElementById('arrow-left')
    const arrowRightElement = document.getElementById('arrow-right')

    const keydonwHandler = (event) => {
      if (event.key === ' ') {
        spaceElement.style.color = '#ffffff'
        spaceElement.style.border = '1.5px solid #ffffff'
      }
      if (event.key === 'ArrowLeft') {
        arrowLeftElement.children[0].src = arrow_white
        arrowLeftElement.style.border = '1.5px solid #ffffff'
      }
      if (event.key === 'ArrowRight') {
        arrowRightElement.children[0].src = arrow_white
        arrowRightElement.style.border = '1.5px solid #ffffff'
      }
    }
    const keyupHandler = (event) => {
      if (event.key === ' ') {
        spaceElement.style.color = '#585858'
        spaceElement.style.border = '1.5px solid #585858'
      }
      if (event.key === 'ArrowLeft') {
        arrowLeftElement.children[0].src = arrow
        arrowLeftElement.style.border = '1.5px solid #585858'
      }
      if (event.key === 'ArrowRight') {
        arrowRightElement.children[0].src = arrow
        arrowRightElement.style.border = '1.5px solid #585858'
      }
    }
    window.onkeydown = keydonwHandler;
    window.onkeyup = keyupHandler

    return () => {
      window.removeEventListener('keydown', keydonwHandler)
      window.removeEventListener('keyup', keyupHandler)
    }  
  },[screenSize])

  return (
    <Container uiPosition={uiPosition} ref={containerRef}>
      <Space id="space" onClick={()=>onClick('space')}>Flip</Space>
      <Arrow id="arrow-left" direction='left' onClick={()=>onClick('arrow-left')}>
        <img src={arrow} alt="arrow" width={25}/>
      </Arrow>
      <Arrow id="arrow-right" direction='right' onClick={()=>onClick('arrow-right')}>
        <img src={arrow} alt="arrow" width={25}/>
      </Arrow>
      <ESC id="esc" onClick={()=>onClick('esc')}>
        ESC
      </ESC>
    </Container>
  )
}

export default Ui