import styled from "styled-components";

export const InnerMenuContainer = styled.div`
  position: absolute;
  z-index: 2;

  transform: translate(-30px);
`;

export const InnerMenuWrapper = styled.div`
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #e6e6e6;
`;

export const Menu = styled.div`
  padding: 0.2rem 0.5rem 0 0.5rem;

  display: flex;
  justify-content: center;

  :hover {
    background-color: #ffdea3;
    border-radius: 8px;
  }
`;

export const Icon = styled.div`
  min-width: 1.5rem;
  display: flex;
`;

export const Text = styled.div`
  width: 5rem;
  font-size: 16px;
`;