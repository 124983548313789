import { useTranslation } from "react-i18next";
import { SortingMenuContainer, SortingMenuOption } from "../SortingMenuStyles";

interface Props {
  selectSorting: (event: string, ascDesc: string) => void
}

const DeckSortingMenu = ({ selectSorting } : Props) => {

  const [ft] = useTranslation('filter')
  
  return (
    <SortingMenuContainer>
      <SortingMenuOption onClick={() => selectSorting('created-at', 'desc')}>{ft('Created date (desc)')}</SortingMenuOption>
      <SortingMenuOption onClick={() => selectSorting('created-at', 'asc')}>{ft('Created date (asc)')}</SortingMenuOption>

      <SortingMenuOption onClick={() => selectSorting('last-opened-at', 'desc')}>{ft('Last open time (desc)')}</SortingMenuOption>
      <SortingMenuOption onClick={() => selectSorting('last-opened-at', 'asc')}>{ft('Last open time (asc)')}</SortingMenuOption>

      <SortingMenuOption onClick={() => selectSorting('name', 'desc')}>{ft('Name (desc)')}</SortingMenuOption>
      <SortingMenuOption onClick={() => selectSorting('name', 'asc')}>{ft('Name (asc)')}</SortingMenuOption>

      <SortingMenuOption onClick={() => selectSorting('card-count', 'desc')}>{ft('Total cards (desc)')}</SortingMenuOption>
      <SortingMenuOption onClick={() => selectSorting('card-count', 'asc')}>{ft('Total cards (asc)')}</SortingMenuOption>

    </SortingMenuContainer>
  )
}

export default DeckSortingMenu;