import styled from "styled-components";

export const ProfileIconMenuContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  width: 15rem;
  padding: 1rem 0px;
  margin-top: 12rem;
  margin-right: 13rem;
  font-size: 16px;

  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const ProfileIconMenuWrapper = styled.div`
  padding: 3px 0px;
  :hover {
    background-color: #ffdea3;
  }
`;

export const LanguageContianer = styled.div`
  border-top: 1px solid #e6e6e6;
  margin-top: 12px;
  padding-left: 12px;
  padding-top: 12px;
  display: flex;
  justify-content: center;
`