import { SideBarContainer, SideBarWrapper } from "./SideBarStyles";
import MenuButton from "./MenuButton";
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { useState } from "react";
import Logo from 'Img/logo.svg';
import Home from 'Img/home.svg';
import HomeSelected from 'Img/homeSelected.svg';
import Menu from 'Img/menu.svg';
import MenuSelected from 'Img/menuSelected.svg';
import Person from 'Img/person.svg';
import PersonSelected from 'Img/personSelected.svg';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

const SideBar = () => {

  const [t] = useTranslation('menu')

  const [open, setOpen] = useState(false)

  const openMenu = () => {
    setOpen(!open)
  }

  const location = useAppSelector((state) => state.reducer.location.currentPage);

  const style = {
    'color': '#000',
    'opacity': '0.8',
  }

  const selectedStyle = {
    'color': '#000',
    'fontWeight': 'bold',
  }

  return (
    <SideBarContainer>
      <SideBarWrapper>
        <img src={Logo} width='80px' style={{margin: '30px 0px'}}/>
        <FaBars className="icon" cursor='pointer' onClick={openMenu}/>
        <Link to='/dashboard' style={{ textDecoration: 'none' }} className={open ? 'menu open' : 'menu'}>
          <MenuButton>
            <img src={location==='dashboard' ? HomeSelected : Home} width='28px'/>
            <div style={location==='dashboard' ? selectedStyle : style}>{t('Dashboard')}</div>
          </MenuButton>
        </Link>
        <Link to='/decks' style={{ textDecoration: 'none' }} className={open ? 'menu open' : 'menu'}>
          <MenuButton>
            <img src={location==='decks' ? MenuSelected : Menu} width='28px'/>
            <div style={location==='decks' ? selectedStyle : style}>{t('Deck list')}</div>
          </MenuButton>
        </Link>
        <Link to='/mypage' style={{ textDecoration: 'none' }} className={open ? 'menu open' : 'menu'}>
          <MenuButton>
            <img src={location==='mypage' ? PersonSelected : Person} width='28px'/>
            <div style={location==='mypage' ? selectedStyle : style}>{t('My page')}</div>
          </MenuButton>
        </Link>
      </SideBarWrapper>
    </SideBarContainer>
  )
};

export default SideBar;