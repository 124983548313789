import { Container, Flag } from "./FlagSelectorStyles"
import En from 'Img/flags/united-states.png'
import Kr from 'Img/flags/south-korea.png'
import Ch from 'Img/flags/china.png'
import Fr from 'Img/flags/france.png'
import Gm from 'Img/flags/germany.png'
import It from 'Img/flags/italy.png'
import Jp from 'Img/flags/japan.png'
import Rs from 'Img/flags/russia.png'
import Sp from 'Img/flags/spain.png'
import Th from 'Img/flags/thailand.png'

const languageMap = {
  us: "en",
  es: "es",
  fr: "fr",
  it: "it",
  jp: "ja",
  kr: "ko",
  ru: "ru",
  th: "th",
  cn: "zh",
  de: "de",
}

interface Props {
  selected: string,
  onClick: (language: string) => void,
  enableList?: any[]
}

const FlagSelector = ({
  selected,
  onClick,
  enableList
}: Props) => {

  const isEnable = (lang: string) => {
    if (!enableList) return undefined
    const res = enableList.findIndex((el) => el.isoCode === lang)
    if (res === -1) return 'unabled'
  }

  return (
    <Container>
      <Flag src={En} className={isEnable("en")} alt="en" onClick={() => onClick("en")} selected={selected === "en"}/>
      <Flag src={Kr} className={isEnable("ko")} alt="kr" onClick={() => onClick("ko")} selected={selected === "ko"}/>
      <Flag src={Ch} className={isEnable("zh")} alt="ch" onClick={() => onClick("zh")} selected={selected === "zh"}/>
      <Flag src={Fr} className={isEnable("fr")} alt="fr" onClick={() => onClick("fr")} selected={selected === "fr"}/>
      <Flag src={Gm} className={isEnable("de")} alt="de" onClick={() => onClick("de")} selected={selected === "de"}/>
      <Flag src={It} className={isEnable("it")} alt="it" onClick={() => onClick("it")} selected={selected === "it"}/>
      <Flag src={Jp} className={isEnable("ja")} alt="ja" onClick={() => onClick("ja")} selected={selected === "ja"}/>
      <Flag src={Rs} className={isEnable("ru")} alt="ru" onClick={() => onClick("ru")} selected={selected === "ru"}/>
      <Flag src={Sp} className={isEnable("es")} alt="es" onClick={() => onClick("es")} selected={selected === "es"}/>
      <Flag src={Th} className={isEnable("th")} alt="th" onClick={() => onClick("th")} selected={selected === "th"}/>
    </Container>
  )
}

export default FlagSelector