import styled from "styled-components";

export const SortingMenuContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 2px solid #e6e6e6;
  font-size: 16px;
  border-radius: 10px;
  padding: 1rem 0px;
  margin-top: 0.3rem;
  z-index: 1;
`;

export const SortingMenuOption = styled.div`
  cursor: pointer;
  opacity: 0.7;
  margin: 0.3rem;
  padding: 2px 1rem;
  :hover {
    opacity: 1;
    background-color: #ffdea3;
  }
`;