import { createAsyncThunk } from "@reduxjs/toolkit";
import { signOut } from "firebase/auth";

export const signOutUser = createAsyncThunk(
  'user/signOut',
  async (payload: {auth: any, userId: string, anonymous: boolean}) => {
    try {
      signOut(payload.auth)
      return true;
    } catch (error) {
      console.error(error);
    }
  }
)