import { useAppSelector } from "Redux/hooks";
import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  min-width: 342px;
  width: 50%;
  min-height: 299px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px #4dc5c5c5;
  padding: 12px 24px;
  box-sizing: border-box;

  font-size: 25px;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  quizNo: number,
  quizSide: string
  sx?: any
}

const QuestionCard = ({quizNo, quizSide, sx}:Props) => {

  const quiz = useAppSelector((state) => state.reducer.quiz.data);
  const quizSettings = useAppSelector((state) => state.reducer.quiz.quizSettings.showCardBy);
  const cards = useAppSelector((state) => state.reducer.cards);
  const shown = quiz?.questions[quizNo]?.shown;
  const cardId = quiz?.questions[quizNo]?.cardRef?.id;
  const card = cards.find((el) => el.id === cardId)

  return (
    <Container style={sx}>
      {(quizSettings === 'front' || quizSettings === 'back' || quizSettings === 'random') ?
        <div>{quizSide === 'front' ? card?.front : card?.back}</div>
      :null}

      {shown ?
        <div>{shown}</div>
      : null}
    </Container>
  )
}

export default QuestionCard;