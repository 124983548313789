import { DeckContainer, DeckWrapper } from "./DeckStyles";

interface Props {
  children?: React.ReactNode 
}

const Deck = ({ children }: Props) => {
  return (
    <DeckContainer>
      <DeckWrapper>
        {children}
      </DeckWrapper>
    </DeckContainer>
  )
};

export default Deck;
