import { ProfileIconMenuContainer, ProfileIconMenuWrapper, LanguageContianer } from "./ProfileIconMenuStyles";
import { useState } from "react";
import SignOutModal from "Components/MyPage/SignOutModal";
import { useTranslation } from "react-i18next";
import Language from "Components/MyPage/Language";

interface Props {
  openMenu: (event: React.MouseEvent) => void,
}

const ProfileIconMenu = ({ openMenu } : Props) => {
  
  const [signOut, setSignOut] = useState(false) 
  const [t] = useTranslation('mypage')

  //Sign out
  const handleSingOut = async(event:any) => {
    event.stopPropagation();
    setSignOut(!signOut);
  }

  return (
    <>
    <ProfileIconMenuContainer onClick={openMenu}>
      <ProfileIconMenuWrapper onClick={handleSingOut}>{t('Sign out')}</ProfileIconMenuWrapper>
      <LanguageContianer>
        <Language />
      </LanguageContianer>
    </ProfileIconMenuContainer>
    {signOut &&
      <SignOutModal closeModal={handleSingOut}/>
    }
    </>
  )
}

export default ProfileIconMenu;