import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface locationState {
  currentPage: string,
  selectedDeck: any,
} 

const initialState: locationState = {
  currentPage: 'dashboard',
  selectedDeck: {},
};

export const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    resetLocation: () => initialState,
    setLocation: (state: any, action: PayloadAction<any>) => state = action.payload
  }
})

export const {resetLocation, setLocation} = location.actions;
export default location.reducer;