import styled from "styled-components";

export const QuizSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const QuizSettingsWrapper = styled.div`
  width: 100%;
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 27px;
`

export const ParameterName = styled.div`
  color: #f4ab29;
  font-size : 15px;
  font-weight: bold; 
  margin-bottom: 12px;
`

export const ParameterWrapper = styled.div`
  margin-bottom: 24px;
`

export const SliderWrapper = styled.div`
  width: 50%;
`

export const RadioWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const ErorrMsgWrapper = styled.div`
  font-size: 13px;
  color: #ad2b2b;
  margin: 12px 0;
`

export const DisableWrapper = styled.div<{isSelected: boolean}>`
  margin-top: 6px;
  pointer-events: ${props => props.isSelected ? '': 'none'};
`