import styled from 'styled-components';

export const FlashCardContainer = styled.div`
  width: 233px;
  height: 148px;
  box-shadow: 0px 18px 22px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 12px 26px 12px 0px;
  display: flex;
  background-color: #fff;
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 12px 0px;
  }
`;
  
export const FlashCardWrapper = styled.div`
  // perspective: 1500px;
  position: absolute;
  width: 188px;

  ::before {
    position: absolute;
    left: 0px;
    border-left: 1rem solid transparent;
    border-bottom: 148px solid transparent;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    content: "";
    background-color: ${props => props.color};
  }
`;

export const FrontSide = styled.div`
  background-color: white;
  margin-left: 28px;
  margin-top: 15px;
  margin-bottom: 7px;
  height: 100%;
  width: 100%;
`;

export const MenuContainer = styled.span`
`;