import styled from "styled-components";

export const QuizSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 12px;
`;

export const QuizSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 6px;
`;

export const ParameterName = styled.div`
  font-size : 18px;
  font-weight: bold; 
  margin-bottom: 12px;
`

export const ParameterWrapper = styled.div`
  margin-bottom: 12px;
`

export const SliderWrapper = styled.div`
  width: 50%;
`

export const ErorrMsgWrapper = styled.div`
  font-size: 13px;
  color: #ad2b2b;
  margin: 12px 0;
`

export const QuizButtonWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  width: 90%;
`

export const DisableWrapper = styled.div<{isSelected: boolean}>`
  margin-top: 6px;
  pointer-events: ${props => props.isSelected ? '': 'none'};
`