import styled from "styled-components";
import { PopupBackground, Close } from "../PopupStyles";
import {FaTimes} from 'react-icons/fa';

type Props = {
  clickHandler?: () => void,
  children?: React.ReactNode,
  title?: string,
  display?: boolean,
}

const PopupContainer = styled.div`
  background-color: white;
  min-width: 310px;
  max-width: 900px;
  width: 80%;
  border-radius: 10px;
  position: relative;
  cursor: initial;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

const Title = styled.div`
  background-color: #f4ab29;
  font-size: 30px;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  padding: 20px 30px;
`

const QuizPopup = ({clickHandler, children, title, display = true} : Props) => {
  return (
    <PopupBackground onClick={clickHandler} isDisplay={display}>
      <PopupContainer onClick={event => event.stopPropagation()}>
        {title? <Title>{title}</Title> : null}
        <Close onClick={clickHandler}><FaTimes /></Close>
        {children}
      </PopupContainer>
    </PopupBackground>
  )
}

export default QuizPopup;