import List from "./components/List/List"
import { useAppSelector } from "Redux/hooks"


const Decks = () => {

  const decks = useAppSelector<any>((state) => state.reducer.decks);

  return (
    <>
      {decks.length < 1 ?
        <></>
        :
        decks.map((element: any, index: number) => (
          <List data={element} key={index} />
        ))
      }
    </>
  )
}

export default Decks