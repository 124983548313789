import styled from "styled-components";

export const Button = styled.button`
  border-radius: 23px;
  background-color: #ffffff;
  font-size: 11px;
  border: 1px solid #000000;
  color: #000000;
  width: auto;
  padding: 7px 26px;
  cursor: pointer;

  :hover{
    background-color: #ffdea3;
  }
`;

export const MenuBotton = styled.button`
  border-radius: 10px;
  width: 80%;
  margin: 5px;
  border: 1px solid #000;
  background-color: #ffffff;
  cursor: pointer;
  padding: 17px 0;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
`

export const QuizButton = styled.button<{height?: string}>`
  background-color: #f4ab29;
  font-size: 15px;
  text-align: center;
  height: ${props => props.height || '100%'};
  width: 100%;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  padding: 15px 0;
  cursor: pointer;
`