import {
  CardModalContainer,
  CardModalButtonContainer,
  InputContainer,
  MenuBotton,
  LanguageWrapper,
} from './CardModalStyles';
import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../Redux/hooks';
import Popup from 'ui/popup/Popup';
import { Input } from 'ui/input/InputStyles';
import { Colors } from 'theme';
import { setLocation } from 'Redux/location/locationSlice';
import { createCard, updateCard } from 'api/cards';
import { getCards } from 'Redux/card/cardThunk';
import FlagSelector from './components/flagSelector/FlagSelector';
import TagList from 'ui/tagList/TagList';
import { setIsLoading } from 'Redux/util/utilSlice';
import { useTranslation } from 'react-i18next';
import {
  setFrontLanguage as _setFrontLanguage,
  setBackLanguage as _setBackLanguage,
} from 'Redux/util/utilSlice';

interface Props {
  openModal: any;
  data?: any;
  action: string;
}

const CardModal = ({ openModal, data, action }: Props) => {
  const [t] = useTranslation('modal');
  const [ct] = useTranslation('common');
  const [question, setQuesiton] = useState<string>(data ? data.front : '');
  const [answer, setAnswer] = useState<string>(data ? data.back : '');
  const [tagColor, setTagColor] = useState<string>(
    data?.tagColor ? data.tagColor.hexCode : '',
  );
  const isLoading = useAppSelector((state) => state.reducer.utils.isLoading);
  const defaultFront = useAppSelector(
    (state) => state.reducer.utils.frontLanguage,
  );
  const defaultBack = useAppSelector(
    (state) => state.reducer.utils.backLanguage,
  );

  const modalHandler = (event: React.MouseEvent) => {
    openModal('card');
    if (event.target !== event.currentTarget) return;
  };

  const questionInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuesiton(event.target.value);
  };

  const answerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(event.target.value);
  };

  const location = useAppSelector((state) => state.reducer.location);
  const dispatch = useAppDispatch();

  const submitHandler = async (addAnother: boolean) => {
    const deckId = window.location.pathname.split('/')[2];
    dispatch(setIsLoading(true));
    if (action === 'edit') {
      type obj = {
        deckId: string;
        cardId: string;
        front: string;
        back: string;
        tagColor?: string;
        skipNulls?: boolean;
        status?: string;
        createdAt?: string;
        createdBy?: string;
        deckRef?: {
          href: string;
          id: string;
        };
        href?: string;
        updatedAt?: string | Date;
        updatedBy?: string;
        frontLanguage: string;
        backLanguage: string;
      };
      const obj: obj = {
        deckId: deckId,
        cardId: data?.id,
        front: question,
        back: answer,
        frontLanguage: frontLanguage,
        backLanguage: backLanguage,
      };
      if (tagColor === '') {
        obj.skipNulls = false;
        obj.createdAt = data.createdAt;
        obj.createdBy = data.createdBy;
        obj.deckRef = data.deckRef;
        obj.href = data.href;
        obj.status = 'active';
        obj.updatedAt = new Date();
        obj.updatedBy = data.createdBy;
      } else {
        obj.tagColor = tagColor;
      }
      await updateCard(obj);
      await dispatch(getCards({ deckId: deckId }));
      openModal('card');
    } else if (action === 'create') {
      type obj = {
        deckId: string;
        front: string;
        back: string;
        tagColor?: string;
        cardId?: string;
        frontLanguage: string;
        backLanguage: string;
      };
      const obj: obj = {
        deckId: deckId,
        front: question,
        back: answer,
        frontLanguage: frontLanguage,
        backLanguage: backLanguage,
      };
      if (!(tagColor === '')) obj.tagColor = tagColor;
      await createCard(obj);
      const locationObj = {
        ...location.selectedDeck,
        activeCardCount: location.selectedDeck.activeCardCount + 1,
      };
      dispatch(
        setLocation({ currentPage: 'cards', selectedDeck: locationObj }),
      );
      await dispatch(getCards({ deckId: deckId }));

      if (addAnother) {
        setQuesiton('');
        setAnswer('');
      } else {
        openModal('card');
      }
    }
    dispatch(setIsLoading(false));
  };

  //Handling tag select
  const handleChosenTag = (event: any) => {
    if (tagColor === event) {
      setTagColor('');
    } else {
      setTagColor(event);
    }
  };

  const [frontLanguage, setFrontLanguage] = useState<string>(
    data ? data.frontLanguage.isoCode : defaultFront ? defaultFront : 'en',
  );
  const [backLanguage, setBackLanguage] = useState<string>(
    data ? data.backLanguage.isoCode : defaultBack ? defaultBack : 'en',
  );
  const setLanguage = (frontBack: string, language: string) => {
    if (frontBack === 'front') {
      dispatch(_setFrontLanguage(language));
      setFrontLanguage(language);
    } else {
      dispatch(_setBackLanguage(language));
      setBackLanguage(language);
    }
  };

  return (
    <Popup clickHandler={modalHandler}>
      <CardModalContainer>
        <div>
          <InputContainer>
            <div
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                paddingBottom: '28px',
              }}
            >
              {t('Create new card')}
            </div>
            <div style={{ fontSize: '13px', color: `${Colors.primary2}` }}>
              {t('Question')}
            </div>
            <Input onChange={questionInput} value={question} />
            <LanguageWrapper>
              <FlagSelector
                selected={frontLanguage}
                onClick={(language: string) => setLanguage('front', language)}
              />
            </LanguageWrapper>
          </InputContainer>
          <InputContainer>
            <div style={{ fontSize: '13px', color: `${Colors.primary2}` }}>
              {t('Answer')}
            </div>
            <Input onChange={answerInput} value={answer} />
            <LanguageWrapper>
              <FlagSelector
                selected={backLanguage}
                onClick={(language: string) => setLanguage('back', language)}
              />
            </LanguageWrapper>
          </InputContainer>
          <InputContainer>
            <div style={{ fontSize: '13px', color: `${Colors.primary2}` }}>
              {t('Tag')}
            </div>
            <TagList
              onClick={(t) => handleChosenTag(t)}
              tagColor={[tagColor]}
            />
          </InputContainer>
        </div>
      </CardModalContainer>
      <CardModalButtonContainer>
        <MenuBotton
          onClick={modalHandler}
          style={{ color: '#e53e3e', border: '1px solid #000' }}
        >
          {ct('Cancel')}
        </MenuBotton>
        <MenuBotton
          onClick={() => submitHandler(false)}
          disabled={
            question.length > 0 && answer.length > 0 && !isLoading
              ? false
              : true
          }
        >
          {ct('Save')}
        </MenuBotton>
        <MenuBotton
          onClick={() => submitHandler(true)}
          disabled={question.length > 0 && answer.length > 0 ? false : true}
          className="addAnother"
        >
          {t('Save and create more')}
        </MenuBotton>
      </CardModalButtonContainer>
    </Popup>
  );
};

export default CardModal;
