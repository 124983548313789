import styled from "styled-components";

export const Container = styled.div`
  padding: 0 20px; 
  font-size: 15px;
`

export const SortContainer = styled.div`
  margin-bottom: 20px;
`

export const DrawerName = styled.div`
  display: flex; 
  align-items: center; 
  margin-bottom: 10px;
`

export const Name = styled.div`
  text-align: left; 
  font-size: 18px;
  font-weight:bold;
`

export const Img = styled.img`
  margin-left: 10px;
  cursor: 'pointer';
  width: 10px;
  margin-bottom: 3px;
`

export const Title = styled.div`
  margin-bottom: 10px; 
  text-align: left; 
  font-size: 15px; 
  font-weight: bold;
`

export const SortWrapper = styled.div` 
  margin-left: 12px;
  text-align: left; 
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FilterContainer = styled.div`
  margin-bottom: 20px;
`