import styled from "styled-components";

export const DeckModalContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
`

export const DeckModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  color: ${props => props.color};
  font-size: 14px;
  background-color: unset;
  border: none;
  margin-right: 10px;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 26px;
`;

export const Title = styled.div`
  font-size: 18px; 
  padding-bottom: 28px;
`

export const InputWrapper = styled.div`
  margin-bottom: 28px;
`

export const FlagWrapper = styled.div`
  margin: 14px 0;
`