import { useAppSelector } from 'Redux/hooks'
import { 
  MobileTopMenuContainer,
  MobileTopMenuWrapper
} from './MobileMenuStyles'
import { useState } from 'react';
import MobileMenuBottom from './component/MobileMenuBottom/MobileMenuBottom';
import CardMenu from './component/CardMenu/CardMenu';
import DeckMenu from './component/DeckMenu/DeckMenu';
import { useTranslation } from 'react-i18next';
import QuizSettingsMenu from './component/QuizSettingsMenu/QuizSettingsMenu';
import QuizMenu from './component/QuizMenu/QuizMenu';
import QuizResultMenu from './component/QuizResultMenu/QuizResultMenu';

const MobileMenu = () => {

  const [t] = useTranslation('menu')
  const location = useAppSelector((state) => state.reducer.location)
  const [menu, setMenu] = useState(false);
  const openMenu = () => setMenu(!menu);

  return (
    <>
      <MobileTopMenuContainer>

        {location.currentPage === 'dashboard' ?
          <MobileTopMenuWrapper>{t('Dashboard')}</MobileTopMenuWrapper>
        :null}

        {location.currentPage === 'decks' ? 
          <MobileTopMenuWrapper>
            <DeckMenu openMenu={openMenu} />
          </MobileTopMenuWrapper>
        :null}

        {location.currentPage === 'cards' ?
          <MobileTopMenuWrapper>
            <CardMenu />
          </MobileTopMenuWrapper>
        :null}

        {location.currentPage === 'mypage' ?
          <MobileTopMenuWrapper>{t('My page')}</MobileTopMenuWrapper>
        :null}

        {location.currentPage === 'quizSettings' ? 
          <MobileTopMenuWrapper><QuizSettingsMenu /></MobileTopMenuWrapper>
        :null}

        {location.currentPage === 'quiz' ? 
          <MobileTopMenuWrapper><QuizMenu /></MobileTopMenuWrapper>
        :null}

        {location.currentPage === 'quizResult' ? 
          <MobileTopMenuWrapper><QuizResultMenu /></MobileTopMenuWrapper>
        :null}

      </MobileTopMenuContainer>

      <MobileMenuBottom 
        menu={menu}
        openMenu={openMenu}
      />
    </>
  )
}

export default MobileMenu