import styled from "styled-components"

type Props ={
  min: number
  max: number
  value: number
  setValue: any
}

const Container = styled.div`
  width: 100%;
`

const Input = styled.input<{ratio: number}>`
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 6px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  border-radius: 40px;

  background-image: linear-gradient(#f4ab29, #f4ab29);
  background-size: ${props => `${props.ratio}% 100%`};
  background-repeat: no-repeat;

  :hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    border-radius: 50%;
    background: #f4ab29; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }

  ::-moz-range-thumb {
    width: 15px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    border-radius: 50%;
    background: #f4ab29; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
`

export const Slider = ({min, max, value, setValue}: Props) => {
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLButtonElement
    setValue(parseInt(target.value)) 
  }

  return (
    <Container>
      <Input 
        ratio={(value-1)/(max-min) * 100}
        type='range' 
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
      />
    </Container>
  )
}