import AnswerCard from "./AnswerCard"
import { useAppSelector } from "Redux/hooks";

interface Props {
  quizNo: number,
  quiz: any,
  onClick: any,
  quizSide: string
  sx?: any
}

const AnswerCardList = ({quiz, quizNo, quizSide, onClick, sx}:Props) => {
  const quizSettings = useAppSelector((state) => state.reducer.quiz.quizSettings.showCardBy);
  const shown = quiz?.questions[quizNo]?.shown;

  let side:'cardSnapshotFront'|'cardSnapshotBack' = 'cardSnapshotBack'
  if (quizSide === 'back') side = 'cardSnapshotFront'

  return (
    <>
      {quiz?.questions[quizNo]?.candidateAnswers.map((question: any, index: number) => (
        <AnswerCard 
          key={index}
          onClick={() => onClick(question)}
          sx={sx}
        >
          {(quizSettings === 'front' || quizSettings === 'back' || quizSettings === 'random') ?
            <div>{index+1}. {quizSide === 'front' ? question.cardSnapshotBack : question.cardSnapshotFront}</div>
          :null}
       
          {shown ?
            <div>{index+1}. {question.cardSnapshotBack}</div>
          : null}
        </AnswerCard>
      ))}
    </>
  )
}

export default AnswerCardList