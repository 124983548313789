import QuizSettings from "./quizSettings/QuizSettings";
import Quiz from "./quiz/Quiz";
import { useState } from "react";
import QuizResult from "./quizResult/QuizResult";

type Props = {
  modalHandler?: () => void
}

const QuizModule = ({modalHandler}: Props) => {

  const [step, setStep] = useState<number>(0);

  return (
    <>
      <QuizSettings display={step===0} close={modalHandler} moveOn={() => setStep(1)}/> 
      {step === 1 ? 
        <Quiz display={step===1} close={modalHandler} moveOn={() => setStep(2)}/>  
      :null}    
      {step === 2 ?
        <QuizResult display={step===2} close={modalHandler} moveOn={() => setStep(0)}/>
      :null}
    </>
  )
};

export default QuizModule;