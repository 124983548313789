import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UtilState {
  isLoading: boolean,
  frontLanguage: string | undefined,
  backLanguage: string | undefined
}

const initialState: UtilState = {
  isLoading: false,
  frontLanguage: undefined,
  backLanguage: undefined
}

export const utils = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setIsLoading: (state: UtilState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setFrontLanguage: (state: UtilState, action: PayloadAction<string>) => {
      state.frontLanguage = action.payload
    },
    setBackLanguage: (state: UtilState, action: PayloadAction<string>) => {
      state.backLanguage = action.payload
    }
  }
})

export const { setIsLoading, setFrontLanguage, setBackLanguage } = utils.actions;
export default utils.reducer;