import styled from "styled-components";

export const FilteringContainer = styled.div`
  border: 2px solid #000;
  border-radius: 23px;
  margin: 0 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilteringWrapper = styled.div`
  display: flex;
  padding: 0 0 0 10px;
`;

export const FilteringOption = styled.div`
  display: flex;
  margin: 0 0.3rem;
`;

export const FilteringOptionDropDown = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilteringOptionIcon = styled.div`
  cursor: pointer;
`;

export const FilteringInput = styled.input`
  margin: 0 0.3rem;
  width: 14rem;
  border: 0px solid;
  background-color: transparent;
  :focus {
    outline: none;
  }
`;

export const TagListContainerBox = styled.div`
  border-radius: 10px;
  position: absolute;
  top: 5rem;
  transform: translateX(-100px);
  padding: 10px;
  z-index: 2;
  font-size: 16px;
  border: 2px solid #e6e6e6;
  background-color: #fff;
`;