import DeckSorting from "./DeckSorting/DeckSorting";
import CardSorting from "./CardSorting/CardSorting";
import { useAppSelector } from "../../../Redux/hooks";
import { FilteringSortingContainer } from "./FilteringSortingStyles";

const FilteringSorting = () => {

  const currentPage = useAppSelector((state) => state.reducer.location.currentPage); 

  return (
    <FilteringSortingContainer>
      {currentPage === 'decks' ? <DeckSorting /> : null}
      {currentPage === 'cards' ? <CardSorting /> : null}
    </FilteringSortingContainer>
  )
}

export default FilteringSorting;