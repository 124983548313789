import { setLocation } from "../Redux/location/locationSlice";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import QuizMobile from "Components/QuizMobile/QuizMobile";

const Quiz = () => {
  const dispatch = useAppDispatch();
  const location = useAppSelector((state) => state.reducer.location)

  useEffect(() => {
    //Change current location state
    dispatch(setLocation({currentPage:'quiz', selectedDeck: location.selectedDeck}));
  }, []);

  return (
    <QuizMobile />
  );
}

export default Quiz;