import styled from "styled-components";

export const TagContainer = styled.div`
  height: 1.8rem;
  margin: 0 0.2rem;
  cursor: pointer;  
  
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: 0.2;
    cursor: unset;
  }
`;
 
export const TagWrapper = styled.div`
  background-color: ${props => props.color};
  width: 62px;
  height: 1.3rem;
  border: 2px solid #e2e2e2;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  &.selected {
    border: 2px solid #f4ab29;
  }
`;