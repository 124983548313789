import { Route, Routes } from 'react-router-dom'
import Dashboard from './Pages/Dashboard';
import Deck from './Pages/Deck';
import QuizMobile from './Pages/QuizMobile';
import DeckList from './Pages/DeckList';
import MyPage from 'Pages/MyPage';
import Landing from 'Pages/Landing';
import QuizSettingsMobile from 'Pages/QuizSettingsMobile';
import QuizResult from 'Pages/QuizResultMobile';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/decks' element={<DeckList />} />
      <Route path='/deck/:id' element={<Deck />} />
      <Route path='/quiz' element={<QuizMobile />} />
      <Route path='/quizSettings' element={<QuizSettingsMobile />} />
      <Route path='/quizResult' element={<QuizResult />} />
      <Route path='/mypage' element={<MyPage/>} />
    </Routes>
  )
}

export default Router;