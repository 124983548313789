import axios from "axios";
import { auth } from "../firebase-config";

export const getUser = async () => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/users/me`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    
    return response;
  } catch (e) {
    return {data: false};
  }
}

export const createUser = async () => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/users/me`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    
    return response;
  } catch (e) {
    console.error(e);
  }
}