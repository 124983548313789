import styled from "styled-components";

export const Container = styled.div`
  padding: 0 20px; 
  font-size: 15px; 
  text-align: left;
`

export const DrawerName = styled.div`
  font-size: 18px; 
  font-weight: bold;
`

export const RadioContainer = styled.div`
  margin-top: 20px; 
  margin-left: 20px;
`