import DeckModal from 'Components/DeckList/components/DeckModal/DeckModal';
import { FaSortAmountUpAlt, FaPlus } from 'react-icons/fa';
import { useState } from 'react';
import { Container, IconContainer } from './DeckMenuStyles';
import { useTranslation } from 'react-i18next';

interface Props {
  openMenu: () => void
}

const DeckMenu = ({
  openMenu,
}: Props) => {

  const [deckModal, setDeckModal] = useState(false);
  const openModal = () => setDeckModal(!deckModal);
  const [t] = useTranslation('menu')

  return (
    <>
      <Container>
        {t('Deck list')}
        <IconContainer>
          <FaSortAmountUpAlt onClick={openMenu} style={{cursor: 'pointer'}} />
          <FaPlus fontSize='20px' onClick={openModal} style={{marginLeft: '10px', cursor: 'pointer'}}/>
        </IconContainer>
      </Container>

      {deckModal ?
        <DeckModal 
          openModal={openModal} 
          action='create'
        />
      :null}
    </>
  )
}

export default DeckMenu