import styled from "styled-components";

export const DoubleCheckBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const DoubleCheckContainer = styled.div`
  background-color: white;
  width: 100%auto;
  border-radius: 15px;
  padding: 3rem;

  display: flex;
  flex-direction: column;
`;

export const DoubleCheckButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  color: ${props => props.color};
  font-size: 14px;
  background-color: unset;
  border: none;
  margin-right: 10px;
  cursor: pointer;
`;
