import { MenuButtonContainer, MenuButtonWrapper } from './MenuButtonStyles';

interface props {
  children: any
}

const MenuButton = ({ children }: props) => {

  return (
    <MenuButtonContainer>
      <MenuButtonWrapper>
        {children}
      </MenuButtonWrapper>
    </MenuButtonContainer>
  )
}

export default MenuButton;