import Ui from "./ui/Ui";
import Card from "./card/Card"
import { CardInterface } from "interface";
import { Dispatch, SetStateAction } from 'react';
import { useState } from "react";

interface Props {
  cardList: CardInterface[],
  selectedCard: number,
  close: () => void,
  setSelectedCard: Dispatch<SetStateAction<number | undefined>>
}

const MobileCard = ({
  cardList=[],
  selectedCard,
  setSelectedCard,
  close
}: Props) => {

  const onClick = (action: string) => {
    if (action === 'arrow-left') {
      if (selectedCard === 0) return
      setShownSide('front')
      setTimeout(() => setSelectedCard(selectedCard - 1), 200)
    }
    if (action === 'arrow-right') {
      if (selectedCard === cardList.length-1) return
      setShownSide('front')
      setTimeout(() => setSelectedCard(selectedCard + 1), 200)
    }
  }

  const [shownSide, setShownSide] = useState('front')
  const handleClick = () => {
    setShownSide((prev) => {
      if (prev === 'front') return ('back')
      return ('front')
    })
  }

  return (
    <>
      <Card 
        front={cardList[selectedCard].front}
        back={cardList[selectedCard].back}
        color={cardList[selectedCard]?.tagColor?.hexCode}
        shownSide={shownSide}
        flip={handleClick}
      />
      <Ui onClick={onClick} onClose={close} />
    </>
  )
}

export default MobileCard