import { QuizButtonContainer } from "./QuizButtonStyles";
import { ReactComponent as Icon} from "Img/quiz.svg";
import { setQuizState } from "Redux/quiz/quizSlice";
import { useAppDispatch } from "Redux/hooks";

const QuizButton = () => {
  
  const modalHandler = () => {
    dispatch(setQuizState({openSettings: true}))
  }
  const dispatch = useAppDispatch();

  return (
    <QuizButtonContainer onClick={modalHandler}>
      <Icon />
    </QuizButtonContainer>
  )
}

export default QuizButton;