import { Container, Wrapper, ColorTag, Content, ContentWrapper } from "./CardStyles"

interface Props {
  front: string,  
  back: string,
  color: string|undefined,
  shownSide: string,
  flip: () => void
}

const Card = ({front, back, color, shownSide, flip}:Props) => {

  const fontSizeFront = Math.max(80 - (front.length * 5), 30);
  const fontSizeBack = Math.max(80 - (back.length * 5), 30);

  return (
    <Container shownSide={shownSide} onClick={flip}>
      <Wrapper side='front'>
        <ContentWrapper>
          <Content fontSize={fontSizeFront}>
            {front}
          </Content>
        </ContentWrapper>
        <ColorTag 
          color={color}
        />
      </Wrapper>
      <Wrapper side='back'>
        <ContentWrapper>
          <Content fontSize={fontSizeBack}>
            {back}
          </Content>
        </ContentWrapper>
        <ColorTag 
          color={color}
        />
      </Wrapper>
    </Container>
  )
}

export default Card