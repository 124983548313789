import { AppContainer, Header, Body } from './AppStyles';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SideMenu from './Components/SideMenu/SideMenu';
import BottomMenu from 'Components/BottomMenu/BottomMenu';
import TopMenu from './Components/TopMenu/TopMenu';
import Router from './router';
import GlobalStyles from './ui/GlobalStyles';
import Preloader from 'ui/preloader/Preloader';

function App() {

  return (
    <>
      <AppContainer>
        <GlobalStyles />
        <BrowserRouter>
          <Header>
            <SideMenu /> 
          </Header>
          <Body>
            <TopMenu />
            <Router /> 
          </Body>
          <BottomMenu />
        </BrowserRouter>
      </AppContainer>
      <Preloader />
    </>
  );
}

export default App;
