import Radio from "ui/radio/Radio";
import BottomDrawer from "ui/drawer/BottomDrawer";
import { useState, useEffect } from "react";
import { Container, DrawerName, RadioContainer } from "./MobileMenuBottomStyles";
import { useDispatch } from "react-redux";
import { setSearch } from "Redux/search/searchSlice";
import { useTranslation } from "react-i18next";

interface Props {
  menu: boolean,
  openMenu: () => void,
}

const MobileMenuBottom = ({
  menu,
  openMenu,
}: Props) => {

  const [ft] = useTranslation('filter')
  const [t] = useTranslation('deck')
  const handleSelect = (n: number) => setSelected(n);
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(1);
  const radioArray = [
    {
      label: ft('Created date (asc)'),
      sort: ['created-at', 'asc']
    },
    {
      label: ft('Created date (desc)'),
      sort: ['created-at', 'desc']
    },
    {
      label: ft('Name (asc)'),
      sort: ['name', 'asc']
    },
    {
      label: ft('Name (desc)'),
      sort: ['name', 'desc']
    },
    {
      label: ft('Last open time (asc)'),
      sort: ['updated-at', 'asc']
    },
    {
      label: ft('Last open time (desc)'),
      sort: ['updated-at', 'desc']
    },
    {
      label: ft('Total cards (desc)'),
      sort: ['card-count', 'desc']
    },
    {
      label: ft('Total cards (asc)'),
      sort: ['card-count', 'asc']
    },
  ]


  const handleSorting = (sort: string, ascDesc: string) => {
    set__Sort(sort);
    if (sort === 'created-at') setSort('Created time');
    if (sort === 'updated-at') setSort('Update time');
    if (sort === 'last-opened-at') setSort('Last Opened');
    if (sort === 'name') setSort('Name');
    if (sort === 'card-count') setSort('Card count');
    setAscDesc(ascDesc);
  }
  const [sort, setSort] = useState<string>('Created time');
  const [__sort, set__Sort] = useState<string>('created-at');
  const [ascDesc, setAscDesc] = useState<string>('desc');

  useEffect(() => {
    dispatch(setSearch({sort: __sort, ascDesc: ascDesc}))
  }, [sort, ascDesc])

  return (
    <BottomDrawer display={menu} closeDrawer={openMenu}>
      <Container>
        <DrawerName>{t('Sort by')}</DrawerName>
        <RadioContainer>
          {radioArray.map((el: any, i: number) => (
            <Radio
              name='sort'
              label={el.label}
              id={i+1}
              value={i+1}
              key={i} 
              selected={selected}
              handleSelect={handleSelect}
              onClick={() => handleSorting(el.sort[0],el.sort[1])}
            />
          ))}
        </RadioContainer>
      </Container>
    </BottomDrawer>
  )
}

export default MobileMenuBottom