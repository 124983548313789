import styled from "styled-components";
import { Colors } from "theme";
import home from 'Img/home.svg';
import menu from 'Img/menu.svg';
import person from 'Img/person.svg';
import homeSelected from 'Img/homeSelected.svg';
import menuSelected from 'Img/menuSelected.svg';
import personSelected from 'Img/personSelected.svg';

export const BottomMenuContainer = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: #fcfcfc;
  border: 1px solid ${Colors.gray1};
  padding: 5px;

  * {
    cursor: pointer;
    
    &:hover {
      stroke: #000000;
      stroke-width: 20;
    }
  }
  
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-evenly;
  }
`;

export const BottomMenuWrapper = styled.div`

`;

export const Home = styled.div`
  background: url(${home}) no-repeat;
  background-size: contain;
  width: 28px;
  height: 28px;

  &:hover {
    background: url(${homeSelected}) no-repeat;
    background-size: contain;
  }

  &.selected {
    background: url(${homeSelected}) no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
  }
`;

export const Menu = styled.div`
  background: url(${menu}) no-repeat;
  background-size: contain;
  width: 28px;
  height: 28px;

  &:hover {
    background: url(${menuSelected}) no-repeat;
    background-size: contain;
  }

  &.selected {
    background: url(${menuSelected}) no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
  }
`;

export const Person = styled.div`
  background: url(${person}) no-repeat;
  background-size: contain;
  width: 28px;
  height: 28px;

  &:hover {
    background: url(${personSelected}) no-repeat;
    background-size: contain;
  }

  &.selected {
    background: url(${personSelected}) no-repeat;
    background-size: contain;
    width: 28px;
    height: 28px;
  }
`;