import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDecks } from "api/decks";

export const getDeckList = createAsyncThunk(
  'decks/getDecks',
  async (payload: {
    status? : string,
    sortField? : string,
    sortDirection? : string,
    limit? : number,
  }) => {
    try {
      const response = await getDecks({
        status : payload?.status,
        sortField : payload?.sortField,
        sortDirection : payload?.sortDirection,
        limit : payload?.limit,
      });
      return response?.data
    } catch (error) {
      return console.error(error);
    }
  }
)