import Popup from 'ui/popup/Popup';
import { Colors } from 'theme';
import { DoubleCheckButtonContainer, Button } from 'Components/DoubleCheck/DoubleCheckStyles';
import { useTranslation } from 'react-i18next';

interface Props {
  handleDoubleCheck: any
  close?: any
}

const EndQuiz = ({handleDoubleCheck, close}: Props) => {

  const [t] = useTranslation('quiz')
  const [ct] = useTranslation('common')

  return (
    <Popup clickHandler={handleDoubleCheck}>
      <div style={{fontSize: '18px', paddingBottom: '28px'}}>{t('Quit quiz')}</div>
      <div style={{fontSize: '14px', paddingBottom: '28px'}}>
        {t('Do you really want to quit the quiz?')}
      </div>
      <DoubleCheckButtonContainer>
        <Button color={Colors.cancel} onClick={handleDoubleCheck}>{ct('No')}</Button>
        <Button color={Colors.confirm} onClick={close}>{ct('Yes')}</Button>
      </DoubleCheckButtonContainer>
    </Popup>
  )
}

export default EndQuiz