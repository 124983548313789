import BottomDrawer from "ui/drawer/BottomDrawer";
import Reset from "Img/reset.svg";
import { useState } from "react";
import Radio from "ui/radio/Radio";
import { MenuBotton } from "ui/button/ButtonStyles";
import TagList from "ui/tagList/TagList";
import { 
  Container, 
  SortContainer, 
  DrawerName, 
  Name, 
  Img,
  SortWrapper,
  Title,
  FilterContainer,
} from "./MobileMenuStyles";
import { useTranslation } from "react-i18next";

interface Props {
  setFilter: any,
  resetDrawer: any,
  apply: any,
  openDrawer: boolean,
  close: () => void
}

const MobileMenu = ({ 
  setFilter, 
  resetDrawer, 
  apply,
  openDrawer,
  close,
}: Props) => {

  const [ft] = useTranslation('filter')
  const [t] = useTranslation('deck')
  const [ct] = useTranslation('common')
  const [selected, setSelected] = useState<number>(0);
  const handleReset = () => {
    setSelected(0)
    setTagColor('')
    resetDrawer()
  }
  const handleSelect = (id: number) : void => setSelected(id);

  const [tagColor, setTagColor] = useState<string>('');
  const handleChosenTag = (color: string) => {
    if (tagColor === color) {
      setTagColor('');
      setFilter({
        tagColor: ''
      })
    } else {
      setTagColor(color);
      setFilter({
        tagColor: color
      })
    }
  }

  const radioArray = [
    {
      label: ft('Created date (asc)'),
      sort: ['created-at', 'asc']
    },
    {
      label: ft('Created date (desc)'),
      sort: ['created-at', 'desc']
    },
    {
      label: ft('Answer (asc)'),
      sort: ['back', 'asc']
    },
    {
      label: ft('Answer (desc)'),
      sort: ['back', 'desc']
    },
    {
      label: ft('Question (asc)'),
      sort: ['front', 'asc']
    },
    {
      label: ft('Question (desc)'),
      sort: ['front', 'desc']
    },
  ]

  return (
    <BottomDrawer display={openDrawer} closeDrawer={close}>
      <Container>
        <SortContainer>
          <DrawerName>
            <Name>{t('Sort · Filter')}</Name>
            <Img src={Reset} alt='reset' onClick={handleReset}/>
          </DrawerName>
          <Title>{t('Sort by')}</Title>
          <SortWrapper>
            {radioArray.map((el, i) => (
              <Radio
                name='sort'
                label={el.label}
                id={i}
                key={i}
                value={i} 
                selected={selected}
                handleSelect={handleSelect}
                onClick={() => setFilter({sort:el.sort[0], ascDesc:el.sort[1]})}
              />
            ))}
          </SortWrapper>
        </SortContainer>
        <FilterContainer>
          <Title>{t('Filter by')}</Title>
          <TagList 
            onClick={(tag)=> handleChosenTag(tag)}
            tagColor={[tagColor]}
          />
        </FilterContainer>
        <MenuBotton onClick={apply}>{ct('Apply')}</MenuBotton>
      </Container>
    </BottomDrawer>
  )
}

export default MobileMenu