import styled from "styled-components"

export const Body = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`

export const ScoreArea = styled.div`
  display: flex;
  gap: 12px;
`

export const ProgressBarWrapper = styled.div`
  flex: 2 0 0;
`

export const ScoreWrapper = styled.div`
  flex: 1 0 0;
  margin-top: 7px;
`

export const QuestionArea = styled.div`
  margin-top: 38px;
  display: flex;
  justify-content: center;
`

export const AnswerAreaSection= styled.section`
  display: flex;
  position: absolute;
  bottom: 100px;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const AnswerArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  row-gap: 20px;
  column-gap: 20px;
`