import styled from "styled-components";

export const Title1 = styled.div``; 

export const Title2 = styled.div`
  font-weight: 800;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
`; 

export const Body1 = styled.div``; 

export const Body2 = styled.div``;

export const Body3 = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #000000;
`;

export const Body4 = styled.div`
  font-size: 9px;
  color: #7a7a7a;
`;