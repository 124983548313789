import styled from "styled-components";

export const UploadModalContainer = styled.div`
  font-size: 15px;
`;

export const Lable = styled.div`
  color: #f4ab29;
  margin-top: 21px;
  font-weight: bold;
`;

export const Button = styled.button`
  border-radius: 10px;
  width: 100%;
  margin: 5px;
  border: 1px solid #000;
  background-color: #ffffff;
  cursor: pointer;
  padding: 12px 0;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.color};
`;

export const Dropdown = styled.select`
  border: none;
  border-bottom: 1px solid #000;
  background-color: #fff;
  width: 100%;
  font-size: 13px;
  margin: 12px 0px;
  padding: 6px;
  cursor: pointer;
`;

export const Menu = styled.div`
  position: absolute;
  border: 2px solid #000;
  height: 100px;
  overflow-y: scroll;
`;