import { Container, Img, Wrapper } from "./FloatingButton.styles";

interface Props {
  img: string,
  onClick: any
}

const FloatingButton = ({
  img,
  onClick
}: Props) => {
  return (
    <Container onClick={onClick}>
      <Wrapper>
        <Img src={img} alt="img" /> 
      </Wrapper>
    </Container>
  )
}

export default FloatingButton