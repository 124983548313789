import { useTranslation } from "react-i18next";
import { InnerMenuContainer, InnerMenuWrapper, Text, Menu } from "./WebMenuStyles";

interface Props {
  innerMenuHandler: any,
  openDoubleCheck: any,
  openModal: any,
}

const WebMenu = ({
  innerMenuHandler,
  openDoubleCheck,
  openModal,
}: Props) => {

  const [t] = useTranslation('common')

  return (
    <InnerMenuContainer onClick={innerMenuHandler}>
      <InnerMenuWrapper>
        <Menu onClick={openDoubleCheck}>
          <Text>{t('Delete')}</Text>
        </Menu>
        <Menu onClick={openModal}>
          <Text>{t('Edit')}</Text>
        </Menu>
      </InnerMenuWrapper>
    </InnerMenuContainer>
  )
}

export default WebMenu