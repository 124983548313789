import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchState {
  sort?: string,
  ascDesc?: string,
  search?: [],
  tagColor?: string,
}

const initialState: SearchState = {sort: 'created-at', ascDesc: 'desc', search: [], tagColor: undefined};

export const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: () => initialState,
    setSearch: (state: SearchState, action: PayloadAction<any>) => {
      return state = {
        ...initialState,
        ...action.payload
      }
    }
  }
})

export const {resetSearch, setSearch} = search.actions;
export default search.reducer;