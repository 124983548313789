import {
  Container,
  ImgWrapper,
  Wrapper,
  ScoreWrapper,
  Score,
  CardContainer,
  Question,
  CardWrapper,
  Answers,
  HistorySection,
  ScoreSection,
} from './QuizResultMobile.styles'
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";
import GoodJob from 'Img/goodjob_mobile.svg';
import Correct from 'Img/correct.svg';
import Wrong from 'Img/wrong.svg';
import { useEffect } from 'react';
import { resetShownSideofQuiz } from 'Redux/quiz/quizSlice';

const Card = ({data, quizSide}: {data: {[key: string]: any}, quizSide: string}) => {

  const [t] = useTranslation('quiz')
  if (!data) return <></>

  const shown = data.shown;
  const question = data.candidateAnswers.find((el: any) => el.isCorrectChoice === true);
  const userChoice = data.candidateAnswers.find((el: any) => el.cardRef.id === data.userChoiceCardRef.id);

  const isCorrect = question?.cardRef.id === userChoice?.cardRef.id ? true : false;

  return (
    <CardContainer>
      <Question>{t('Question')}: {shown? shown : quizSide === 'front' ? question.cardSnapshotFront : question.cardSnapshotBack}</Question>
      <CardWrapper>
        <Answers>
          <div className="label">{t('Correct: ')}</div>
          <div className={isCorrect?'answer':'wrong-answer'}>{shown? question.cardSnapshotBack : quizSide === 'front' ? question.cardSnapshotBack : question.cardSnapshotFront}</div>
        </Answers>
        <Answers>
          <div className="label">{t('Selected: ')}</div>
          <div className={isCorrect?'answer':'wrong-answer'}>{shown? userChoice?.cardSnapshotBack : quizSide === 'front' ? userChoice?.cardSnapshotBack : userChoice?.cardSnapshotFront}</div>
        </Answers>
      </CardWrapper>
    </CardContainer>
  )
}

const QuizResultMobile = () => {

  const quiz = useAppSelector((state) => state.reducer.quiz.data);
  const [t] = useTranslation('quiz')
  const shownSideList = useAppSelector((state) => state.reducer.quiz.quizSettings.shownSideList)
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetShownSideofQuiz())
    }
  },[])

  return (
    <Container>
      <ImgWrapper>
        <img width="140px" src={GoodJob} alt="goodjob" />
      </ImgWrapper>
      <ScoreSection>
        <ScoreWrapper>
          <img width='60px' src={Correct} alt="correct" />
          <Score>
            <div>{t('Correct')}</div>
            <div className="score">{quiz.totalAnsweredCorrect}</div>
          </Score>
        </ScoreWrapper>
        <ScoreWrapper>
          <img width='60px' src={Wrong} alt="wrong" />
          <Score>
            <div>{t('Incorrect')}</div>
            <div className="score">{quiz.totalAnsweredIncorrect}</div>
          </Score>
        </ScoreWrapper>
      </ScoreSection>
      <Wrapper>
        <HistorySection>
          {quiz.questions.map((el: {[key: string]: any}, i: number) => (
            <Card key={i} data={el} quizSide={shownSideList[i]}/>
          ))}
        </HistorySection>
      </Wrapper>
    </Container>
  )
}

export default QuizResultMobile