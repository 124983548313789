import { DeckContainer } from './ListStyles';
import InnerMenu from './components/InnerMenu/InnerMenu';
import { useState, useRef, useEffect } from 'react';
import DeckModal from '../DeckModal/DeckModal';
import DoubleCheck from '../../../DoubleCheck/DoubleCheck';
import { Link } from "react-router-dom";
import { useAppDispatch } from 'Redux/hooks';
import { setLocation } from 'Redux/location/locationSlice';
import DeckUi from './components/DeckUi';

interface Props {
  data: any;
}

const List = ({data}: Props) => {

  const dispatch = useAppDispatch();

  const handleClick = () => dispatch(setLocation({currentPage:'decks', selectedDeck: data}));

  //InnerMenu component
  const [openInnerMenu, SetOpenInnerMenu] = useState<boolean>(false);
  const innerMenuHandler = (event: React.MouseEvent) => {
    SetOpenInnerMenu(!openInnerMenu);
    event.preventDefault();
  }

  //Outside click detecting
  const ref = useRef<any>();
  useEffect(()=>{
    const handleClickOutside=(event: any)=>{
      if (ref.current){
        if (!ref.current.contains(event.target)) {
          SetOpenInnerMenu(false);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  //Edit modal component
  const [modal, setModal] = useState<boolean>(false);
  const openModal = () => setModal(!modal);

  //Delete modal component
  const [doubleCheck, setDoubleCheck] = useState<boolean>(false);
  const openDoubleCheck = () => setDoubleCheck(!doubleCheck);

  return (
    <DeckContainer ref={ref}>
      <Link 
        to={`/deck/${data.id}`} 
        style={{textDecoration: 'none'}}
        onClick={handleClick}
      >
        <DeckUi data={data} onClick={innerMenuHandler}/>
      </Link>
      {openInnerMenu ?
        <InnerMenu 
          data={data} 
          innerMenuHandler={innerMenuHandler} 
          openModal={openModal} 
          openDoubleCheck={openDoubleCheck}
        />
      :null}
      {doubleCheck ? 
        <DoubleCheck 
          openDoubleCheck={openDoubleCheck} 
          data={data} 
          item='decks'/>
      :null}
      {modal ?
        <DeckModal 
          openModal={openModal} 
          data={data} 
          action='edit'/>
      :null}
    </DeckContainer>
  )
}

export default List;