import axios from "axios"
import { auth } from "../firebase-config"

export const getCards = async (params: 
  {
    deckId: string, 
    status?: string,
    sortField?: string,
    sortDirection?: string,
    limit?: number,
    tagColor?: string,
  }) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/decks/${params.deckId}/cards`,
      params: {
        "status": params.status,
        "sort-field": params.sortField,
        "sort-direction": params.sortDirection,
        "limit": params.limit,
        "tag-colors": params.tagColor
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    return response;
  } catch (e) {
    console.error(e)
  }
}

export const getCard = async (params: {deckId: string, cardId: string,}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER}/decks/${params.deckId}/cards/${params.cardId}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    return response;
  } catch (e) {
    console.error(e)
  }
}

export const createCard = async (params: 
  {
    deckId: string, 
    front: string, 
    back: string, 
    frontLanguage: string,
    backLanguage: string,
    tagColor?: string,
  }) => {
    
  const token = await auth.currentUser?.getIdToken().then((token)=> token)
  const body:any = {
    method: 'post',
    url: `${process.env.REACT_APP_SERVER}/decks/${params.deckId}/cards`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    data: {
      "front": params.front,
      "frontLanguage": {
        "isoCode": params.frontLanguage,
      },
      "back": params.back,
      "backLanguage": {
        "isoCode": params.backLanguage,
      },
    }
  }
  if (params?.tagColor) body.data['tagColor'] = {
    hexCode: params.tagColor
  }

  try {
    const response = await axios(body)
    return response;
  } catch (e) {
    console.error(e)
  }
}

export const updateCard = async (params: 
  {
    deckId: string, 
    cardId: string, 
    front?: string, 
    back?: string, 
    tagColor?: string, 
    status?: string, 
    skipNulls?: boolean,
    createdAt?: string,
    createdBy?: string,
    deckRef?: {
      href: string,
      id: string,
    },
    href?: string,
    updatedAt?: string | Date,
    updatedBy?: string,
    frontLanguage?: string,
    backLanguage?: string,
  }) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)
  const body: any = {
    method: 'put',
    url: `${process.env.REACT_APP_SERVER}/decks/${params.deckId}/cards/${params.cardId}`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    params: {
      "_skip-nulls": params.skipNulls,
    },
    data: {
      "front": params.front,
      "frontLanguage": {
        "isoCode": params.frontLanguage,
      },
      "back": params.back,
      "backLanguage": {
        "isoCode": params.backLanguage,
      },
      "status": params.status,
      'createdAt': params.createdAt,
      'createdBy': params.createdBy,
      'deckRef': params.deckRef,
      'href': params.href,
      'updatedAt': params.updatedAt,
      'updatedBy': params.updatedBy,
    }
  }
  if (params?.tagColor) body.data['tagColor'] = {
    hexCode: params.tagColor
  }

  try {
    const response = await axios(body)
    return response;
  } catch (e) {
    console.error(e)
  }
}

export const searchCards = async (params:{
  query: string,
  deckId?: string,
  status?: string,
  sortField?: string,
  sortDirection?: string,
  limit?: number,
  tagColor?: string,
}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/cards/search`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      params: {
        "q": params.query,
        "deck-id": params.deckId,
        "status": params.status,
        "sort-field": params.sortField,
        "sort-direction": params.sortDirection,
        "limit": params.limit,
        "tag-colors": params.tagColor
      },
    })
    return response;
  } catch (e) {
    console.error(e)
  }
}