import { SignInContainer, Social, SocialSignInContainer, Line, Container } from "./SignInStyles";
import google from "../../../Img/google.svg";
import { auth } from "../../../firebase-config";
import { useAppDispatch } from "../../../Redux/hooks";
import { setUserState } from "../../../Redux/user/userSlice";
import img from "../../../Img/profile.png";
import { Button } from "ui/button/ButtonStyles";
import { signInAnonymously, getRedirectResult } from "firebase/auth"; 
import { createUser } from "api/users";
import { googleSignIn } from "api/auth";
import { setIsLoading } from "Redux/util/utilSlice";

const SignIn = () => {

  const dispatch = useAppDispatch();

  //Google Oauth
  const hangleGoogleSignin = async () => {
    dispatch(setIsLoading(true))
    const userInfo = await googleSignIn()
    if (userInfo) {
      const action = {
        userId: userInfo?.id,
        email: userInfo?.email,
        img: userInfo?.avatarUri,
        name: userInfo?.name,
        signin: true,
        anonymouse: false,
      }
      dispatch(setUserState(action));
    }
    dispatch(setIsLoading(false))
  }

  // Get google signin result and set user state
  getRedirectResult(auth).then( async (result: any) => {
    if (result) {
      const action = {
        userId: result.user.uid,
        email: result.user.email,
        img: result.user.photoURL,
        name: result.user.displayName,
        signin: true,
        anonymouse: false,
        token: auth.currentUser,
      }
      dispatch(setUserState(action));
    }
  })

  //Anonymous
  const anonymousSignIn = async () => {
    dispatch(setIsLoading(true))
    const authInfo = await signInAnonymously(auth);
    if (authInfo) {
      const userId = authInfo.user.uid;
      const res = await createUser();
  
      dispatch(setUserState({
        userId: userId,
        email: null,
        img: img,
        name: 'Anonymous',
        signin: true,
        anonymous: true
      }))
    }
    dispatch(setIsLoading(false))
  }

  return (
    <Container>
      <SignInContainer>

        {/* Google Signin */}
        <SocialSignInContainer onClick={hangleGoogleSignin}>
          <Social src={google} />
          <div style={{marginLeft: '10px'}}>Sign in with Google account</div>
        </SocialSignInContainer>

        {/* Anonymous Signin */}
        <div style={{margin: '32px 0', fontSize: '15px'}}>
          <Line><span style={{background: '#fff', padding: '0 10px'}}>or</span></Line>
        </div>
        <div>Use the app anonymously</div>
        <Button style={{margin: '10px 0 0 0'}} onClick={anonymousSignIn}>Start</Button>

      </SignInContainer>
    </Container>
  )
}

export default SignIn;