import { SortingContainer, SortingDropDown, SortingText, SortingWrapper } from "../SortingStyles";
import { useState, useEffect, useRef } from "react";
import DeckSortingMenu from "./DeckSortingMenu";
import { useAppDispatch } from "../../../../Redux/hooks";
import { setSearch } from "../../../../Redux/search/searchSlice";
import Down from 'Img/down.svg';
import { useTranslation } from "react-i18next";

const DeckSorting = () => {

  const [ft] = useTranslation('filter')

  const [sort, setSort] = useState<string>(ft('Created date (desc)'));
  const [__sort, set__Sort] = useState<string>('created-at');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [ascDesc, setAscDesc] = useState<string>('desc');

  const isFirstRender = useRef(true);
  const dispatch = useAppDispatch();

  const handleSortingClick = () => {
    setOpenMenu(!openMenu);
  }

  const selectSorting = (event: string, ascDesc: string) => {
    set__Sort(event);
    setAscDesc(ascDesc);
    if (event === 'created-at' && ascDesc === 'desc') {
      setSort(ft('Created date (desc)'));
    }
    if (event === 'created-at' && ascDesc === 'asc') {
      setSort(ft('Created date (asc)'));
    }
    if (event === 'last-opened-at' && ascDesc === 'desc') {
      setSort(ft('Last open time (desc)'));
    }
    if (event === 'last-opened-at' && ascDesc === 'asc') {
      setSort(ft('Last open time (asc)'));
    }
    if (event === 'name' && ascDesc === 'desc') {
      setSort(ft('Name (desc)'));
    }
    if (event === 'name' && ascDesc === 'asc') {
      setSort(ft('Name (asc)'));
    }
    if (event === 'card-count' && ascDesc === 'desc') {
      setSort(ft('Total cards (desc)'));
    }
    if (event === 'card-count' && ascDesc === 'asc') {
      setSort(ft('Total cards (asc)'));
    }
  }

  const ref = useRef<any>();
  useEffect(()=>{
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    dispatch(setSearch({sort: __sort, ascDesc: ascDesc}));
    
    //Outside click detecting
    const handleClickOutside=(event: any)=>{
      if (ref.current){
        if (!ref.current.contains(event.target)) {
          setOpenMenu(false);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [sort,ascDesc])

  return (
    <SortingContainer>
      <SortingWrapper ref={ref}>
        <SortingDropDown onClick={handleSortingClick}>
          <SortingText>{sort}</SortingText>
          <img src={Down} style={{margin: '10px'}}/>
        </SortingDropDown>
      </SortingWrapper>
      {openMenu ? <DeckSortingMenu selectSorting={selectSorting} /> : null}
    </SortingContainer>
  )
}

export default DeckSorting;