import styled from "styled-components";

export const Container = styled.div`
  display: none;
  width: 90%;
  font-size: 15px;
  padding: 12px;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Wrapper = styled.div`
  cursor: pointer; 
  align-items: center; 
  display: flex;
  justify-content: center;
`

export const CardCounter = styled.div`
  font-size: 12px;
  font-weight: 300;
`