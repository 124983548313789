import styled from "styled-components";

export const Container = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;
`

export const BackImg = styled.img`
  margin-right: 15px;
  cursor: pointer;
`