import { useTranslation } from "react-i18next";
import { CTA } from "./NoResultsStyles"
import { Button } from "ui/button/ButtonStyles";

interface Props {
  onClick: () => void
}

const NoResults = ({
  onClick
}: Props) => {

  const [t] = useTranslation('deck')

  return (
    <CTA>
      <div>{t('Could not find cards.')}</div>
      <div style={{marginBottom: '20px'}}>{t('Please change or reset the filter.')}</div>
      <Button onClick={onClick}>{t('Reset')}</Button>
    </CTA>
  )
}

export default NoResults