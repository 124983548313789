import styled from "styled-components";

export const CTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
  width: 100%;
  margin: 1rem 0;
  font-size: 16px;
  & div {
    margin-top: 1rem;
  }
`;
