import axios from "axios";
import { auth } from "../firebase-config";

export const getQuiz = async (params: {quizId: string}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/quizzes/${params.quizId}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    
    return response;
  } catch (e) {
    console.error(e);
  }
}

export const getQuestions = async (params: {quizId: string}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/quizzes/${params.quizId}/questions`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    
    return response;
  } catch (e) {
    console.error(e);
  }
}

export const updateQuiz = async (params: {quizId: string, questionId: string, data?: any}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER}/quizzes/${params.quizId}/questions/${params.questionId}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      data: params.data,
    })
    
    return response;
  } catch (e) {
    console.error(e);
  }
}

export const getQuestion = async (params: {quizId: string, questionId: string}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/quizzes/${params.quizId}/questions/${params.questionId}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    
    return response;
  } catch (e) {
    console.error(e);
  }
}

export const createQuiz = async (params: {deckId: string, questionCount: number, tagColors?: string}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/quizzes/create`,
      params: {
        'deck-id': params.deckId,
        'question-count': params.questionCount,
        'tag-colors': params.tagColors,
        'answer-choice-count': 4,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    
    return response;
  } catch (e: any) {
    return e.response.data;
  }
}

export const generateContentQuiz = async (params: 
  {
    quizId: string, 
    questionId: string,
    cardSide: string,
    format?: string,
    blankToken?: string,
  }) => {
  
  const token = await auth.currentUser?.getIdToken().then((token) => token)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/quizzes/${params.quizId}/questions/${params.questionId}/generate-content`,
      params: {
        'card-side': params.cardSide,
        'format': params.format,
        'blank-token': params.blankToken,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      }
    })
    return response;
  } catch (e) {
    console.error(e);
  }
}