import styled from "styled-components";

export const DeckContainer = styled.div`
  background-color: white;
  width: 310px;
  max-height: 110px;
  left: 25px;
  top: 313px;
  border-radius: 10px;
  box-shadow: 0px 18px 22px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0px 18px 22px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffdea3;
  }
`;

export const DeckWrapper = styled.div`
  padding: 15px 17px;
`;