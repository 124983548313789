import { useAppSelector } from "Redux/hooks";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding-bottom: 25px;
`

const Wrapper = styled.div`
  border-radius: 21px;
  border: 1px solid #eeeeee;
  width: 100%;
`

const Bar = styled.div<{width: string}>`
  background-color: #f4ab29;
  border-radius: 21px;
  height: 42px;
  width: ${props => props.width || '100px'};
`

const CounterWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 10px;
  display: flex;
  align-items: flex-end;
  gap: 4px;
` 

const Current = styled.div`
  font-size: 25px;
  color: #f4ab29;
  line-height: 15px;
`

const Total = styled.div`
  font-size: 13px;
  color: #b4b4b4;
  line-height: 9px;
`

const Progressbar = () => {

  const quiz = useAppSelector((state) => state.reducer.quiz.data);
  const percentComplete = (quiz.totalQuestions - quiz.totalUnanswered)/quiz.totalQuestions * 100;

  return (
    <Container>
      <Wrapper>
        <Bar width={`${percentComplete}%`} />
      </Wrapper>
      <CounterWrapper>
        <Current>{quiz.totalQuestions - quiz.totalUnanswered}</Current><Total>/ {quiz.totalQuestions}</Total>
      </CounterWrapper>
    </Container>
  )
}

export default Progressbar;