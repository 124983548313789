import styled from 'styled-components';

export const DrawerBackground = styled.div<{ display: string }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.display};
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const BottomDrawerContainer = styled.div`
  position: fixed;
  z-index: 101;
  bottom: 0px;
  background-color: #fff;
  width: 90%;
  max-height: 70vh;
  overflow-y: scroll;
  border-radius: 12px 12px 0 0;
  text-align: center;
  padding: 30px 0px;
  animation: translateY 1s forwards;

  @keyframes translateY {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 23px;
  cursor: pointer;
`;