import { DoubleCheckButtonContainer, Button} from './DoubleCheckStyles';
import { useAppSelector, useAppDispatch } from "../../Redux/hooks";
import { getDeckList } from "../../Redux/deck/deckThunk";
import Popup from 'ui/popup/Popup';
import { Colors } from 'theme';
import { updateDeck } from 'api/decks';
import { useNavigate } from 'react-router-dom';
import { updateCard } from 'api/cards';
import { setLocation } from "Redux/location/locationSlice";
import { getCards } from "Redux/card/cardThunk";
import { useTranslation } from 'react-i18next';
import { setIsLoading } from 'Redux/util/utilSlice';

interface Props {
  openDoubleCheck: (event: React.MouseEvent) => void,
  data: any,
  item: string
}

const DoubleCheck = ({openDoubleCheck, data, item} : Props) => {

  const [commonT] = useTranslation('common')
  const [t, i18n] = useTranslation('deckList')

  const modalHandler = (event : React.MouseEvent) => {
    openDoubleCheck(event);
  };
  const navigate = useNavigate();
  const location = useAppSelector((state) => state.reducer.location);
  const dispatch = useAppDispatch();
  
  const submitHandler = async (event : React.MouseEvent) => {
    dispatch(setIsLoading(true))
    if (item === 'decks') {
      await updateDeck({id: data.id, status: 'deleted'})
      if (location.currentPage === 'decks') {
        await dispatch(getDeckList({})).unwrap();
        openDoubleCheck(event);
      } else {
        openDoubleCheck(event);
        dispatch(setIsLoading(false))
        navigate('/decks')
        return
      }
    }
    if (item === 'cards') {
      const locationObj = {
        ...location.selectedDeck,
        activeCardCount: location.selectedDeck.activeCardCount -1
      }
      dispatch(setLocation({currentPage:'decks', selectedDeck: locationObj}));
      await updateCard({deckId: data.deckRef.id, cardId: data.id, status: 'deleted',})
      await dispatch(getCards({deckId: data.deckRef.id,}))
    }
    dispatch(setIsLoading(false))
    openDoubleCheck(event);
  }

  const commentDeck = (i18n.language === 'ko' || i18n.language === 'ko-KR') ? `정말로 ‘${data.name}’ 덱을 삭제할 건가요?` : `Do you really want to delete ${data.name}?`
  
  return (
    <Popup clickHandler={modalHandler}>
      <div style={{fontSize: '18px', paddingBottom: '28px', fontWeight: 'normal'}}>{item === 'decks' ? t('Delete deck') : t('Delete card')}</div>
      <div style={{fontSize: '14px', paddingBottom: '28px', fontWeight: 'normal'}}>
        {item === 'decks' ? commentDeck : t('Do you really want to delete this card?')}
      </div>
      <DoubleCheckButtonContainer>
        <Button color={Colors.cancel} onClick={modalHandler}>{commonT('No')}</Button>
        <Button color={Colors.confirm} onClick={submitHandler}>{commonT('Yes')}</Button>
      </DoubleCheckButtonContainer>
    </Popup>
  )
}

export default DoubleCheck;