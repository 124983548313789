import styled from "styled-components";

export const PopupBackground = styled.div<{isDisplay: boolean}>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isDisplay? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const PopupContainer = styled.div`
  background-color: white;
  min-width: 310px;
  max-width: 400px;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  cursor: initial;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

export const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 23px;
  cursor: pointer;
`;