import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCards as getCardsApi } from "api/cards";

export const getCards = createAsyncThunk(
  'cards/getCards',
  async (payload: {
    deckId: string, 
    sortField?: string,
    sortDirection?: string,
    tagColor?: string, 
  }) => {
    try {
      const response = await getCardsApi({
        deckId: payload.deckId, 
        sortField: payload.sortField,
        sortDirection: payload.sortDirection,
        tagColor: payload.tagColor, 
      });
      return response?.data;
    } catch (error) {
      return console.error(error);
    }
  }
)