import styled from "styled-components";
import { Colors } from "theme";

export const AddDeckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`;

export const AddDeckWrapper = styled.div`
  cursor: pointer;
  width: 150px;
  height: 105px;
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  font-size: 12px;
  color: ${Colors.gray2};
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #ffdea3
  }

  @media (max-width: 768px) {
    width: 310px;
    height: 91px;
  }
`;