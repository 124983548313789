import { FaSortDown } from "react-icons/fa";
import { SortingContainer, SortingDropDown, SortingText, SortingWrapper, SrotingIcon } from "../SortingStyles";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { setSearch } from "../../../../Redux/search/searchSlice";
import CardSortingMenu from "./CardSortingMenu";
import { useTranslation } from "react-i18next";

const CardSorting = () => {

  const [ft] = useTranslation('filter')
  const [sort, setSort] = useState<string>(ft('Created date (desc)'));
  const [__sort, set__Sort] = useState<string>('created-at');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [ascDesc, setAscDesc] = useState<string>('desc');
  
  const dispatch = useAppDispatch();
  const search = useAppSelector((state) => state.reducer.search); 
  const isFirstRender = useRef(true);

  const handleSortingClick = () => {
    setOpenMenu(!openMenu);
  }

  const selectSorting = (event: string, ascDesc: string) => {
    set__Sort(event);
    setAscDesc(ascDesc);
    if (event === 'created-at' && ascDesc === 'asc') {
      setSort(ft('Created date (asc)'));
    }
    if (event === 'created-at' && ascDesc === 'desc') {
      setSort(ft('Created date (desc)'));
    }
    if (event === 'back' && ascDesc === 'asc') {
      setSort(ft('Answer (asc)'));
    }
    if (event === 'back' && ascDesc === 'desc') {
      setSort(ft('Answer (desc)'));
    }
    if (event === 'front' && ascDesc === 'asc') {
      setSort(ft('Question (asc)'));
    }
    if (event === 'front' && ascDesc === 'desc') {
      setSort(ft('Question (desc)'));
    }
  }

  const ref = useRef<any>();
  useEffect(()=>{
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    dispatch(setSearch({sort: __sort, ascDesc: ascDesc, search: search.search, tagColor: search.tagColor}));
    
    //Outside click detecting
    const handleClickOutside=(event: any)=>{
      if (ref.current){
        if (!ref.current.contains(event.target)) {
          setOpenMenu(false);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [sort, ascDesc])

  return (
    <SortingContainer>
      <SortingWrapper ref={ref}>
        <SortingDropDown onClick={handleSortingClick}>
          <SortingText>{sort}</SortingText>
          <SrotingIcon><FaSortDown /></SrotingIcon>
        </SortingDropDown>
      </SortingWrapper>
      {openMenu ? <CardSortingMenu selectSorting={selectSorting} /> : null}
    </SortingContainer>
  )
}

export default CardSorting;