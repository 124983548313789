import { useAppSelector } from "Redux/hooks";
import { Container, BackImg } from "./QuizSettingsMenu.styles"
import Backarrow from "Img/backarrow.svg";
import { useNavigate } from "react-router-dom";

const QuizSettingsMenu = () => {

  const selectedDeck = useAppSelector((state) => state.reducer.location.selectedDeck)
  const navigate = useNavigate()
  return (
    <Container>
      <BackImg src={Backarrow} alt={'back'} onClick={() => navigate(`/deck/${selectedDeck.id}`)}/>
      Quiz settings
    </Container>
  )
}

export default QuizSettingsMenu