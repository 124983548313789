import { useEffect } from 'react';
import { PopupBackground, PopupContainer, Close } from './PopupStyles';
import { FaTimes } from 'react-icons/fa';

interface Props {
  children?: React.ReactNode;
  clickHandler?: React.MouseEventHandler<HTMLDivElement>;
  display?: boolean;
}

const Popup = ({ children, clickHandler, display = true }: Props) => {
  useEffect(() => {
    const background = document.querySelector('.background');
    const container = document.querySelector('.container');

    background?.addEventListener('mousedown', (event: any) => {
      if (!clickHandler) return;
      clickHandler(event);
    });
    container?.addEventListener('mousedown', (event) => {
      event.stopPropagation();
    });
    return () => {
      background?.removeEventListener('mousedown', (event: any) => {
        if (!clickHandler) return;
        clickHandler(event);
      });
      container?.removeEventListener('mousedown', (event) => {
        event.stopPropagation();
      });
    };
  }, []);

  return (
    <PopupBackground className="background" isDisplay={display}>
      <PopupContainer
        className="container"
        onClick={(event) => event.stopPropagation()}
      >
        <Close onClick={clickHandler}>
          <FaTimes />
        </Close>
        {children}
      </PopupContainer>
    </PopupBackground>
  );
};

export default Popup;
