import { FaTimes } from 'react-icons/fa';
import { Container } from './QuizMenu.styles';
import { useAppSelector } from 'Redux/hooks';
import { useNavigate } from 'react-router-dom';

const QuizMenu = () => {

  const location = useAppSelector((state) => state.reducer.location)
  const navigate = useNavigate()

  return (
    <Container>
      Quiz
      <FaTimes onClick={() => navigate(`/deck/${location.selectedDeck.id}`) }/>
    </Container>
  )
}

export default QuizMenu