import Search from "Img/search.svg";
import Close from "Img/close.svg";
import styled from "styled-components";

interface Props {
  handleClose: any,
  handleSearch: any,
  keyword: string,
  handleChange: any,
}

const InputContainer = styled.div`
  border-radius: 19px;
  width: 65vw;
  height: 33px;
  box-sizing: border-box;
  border: 2.5px solid #000;
  display: flex;
  align-items: center;
  justify-content: flex-even;
  background-color: #fff;
  position: relative;
`;

const Input = styled.input`
  border: none;
  width: 70%;

  &:focus {
    outline: none;
  }
`;

const Icon = styled.div`
  position: absolute;
  right: 10px;
`;

export const SearchInput = ({handleClose, handleSearch, keyword, handleChange}:Props) => {

  return (
    <InputContainer>
      <img src={Search} style={{margin: '0 12px'}} onClick={handleSearch}/>
      <Input id='input' value={keyword} placeholder='Search' onChange={handleChange} onKeyUp={handleSearch}/>
      <Icon><img src={Close} onClick={handleClose} /></Icon>
    </InputContainer>
  )
}