import { FaPlus } from 'react-icons/fa';
import Backarrow from "Img/backarrow.svg";
import { SearchInput } from 'ui/input/SearchInput';
import Search from "Img/search.svg";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAppSelector } from 'Redux/hooks';
import CardModal from 'Components/FlashCard/CardModal';
import { Container, BackImg, DeckName, PlusContainer, SearchImg, QuizContainer, QuizImg } from './CardMenuStyles';
import { setCards } from "Redux/card/cardSlice";
import { getCards } from "Redux/card/cardThunk";
import { useDispatch } from 'react-redux';
import { searchCards } from "api/cards";
import quizIcon from 'Img/quiz_nosquare.svg';

const CardMenu = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const deckId = window.location.pathname.split('/')[2];
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const openSearchInput = (): void => {
    setOpenSearch(!openSearch)
  }
  const search = useAppSelector((state) => state.reducer.search); 
  const location = useAppSelector((state) => state.reducer.location)
  const [cardModal, setCardModal] = useState(false);
  const openModal = () => setCardModal(!cardModal);
  const [keyword, setKeyword] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  }

  const handleSearch = async (event: any) => {
    if (event.key === 'Enter' || event.type === 'click') {
      if (keyword.length === 0) {
        const getCardList = async () => {
          dispatch(getCards({
            deckId: deckId, 
            sortField: search.sort, 
            sortDirection: search.ascDesc, 
            tagColor: search.tagColor,
          }))
        };
        getCardList();
      } else {
        const res = await searchCards({
          query: keyword,
          deckId: deckId,
          sortField: search.sort,
          sortDirection: search.ascDesc,
          tagColor: search.tagColor,
        });
        dispatch(setCards(res?.data))
      }
    }
  }

  const takeQuiz = () => {
    navigate('/quizSettings')
  }

  return (
    <>
      <Container>
        <BackImg src={Backarrow} alt={'back'} onClick={() => navigate(-1)}/>
        <DeckName>
          {(!openSearch && location.selectedDeck) ? location.selectedDeck.name : null}
        </DeckName>
        {openSearch ? 
          <SearchInput 
            handleClose={openSearchInput} 
            handleSearch={handleSearch} 
            handleChange={handleChange} 
            keyword={keyword}
          />
        :null}
      </Container>


      <PlusContainer>
        {!openSearch ? 
          <SearchImg src={Search} alt={'search'} onClick={openSearchInput}/>
        :null}
        <QuizContainer onClick={takeQuiz}>
          <QuizImg src={quizIcon} alt='quiz' ></QuizImg>
        </QuizContainer>
        <FaPlus fontSize='20px' onClick={openModal} style={{cursor: 'pointer'}}/>
      </PlusContainer>

      {cardModal ?
        <CardModal
          openModal={openModal}
          action='create'
        /> 
      :null}
    </>
  )
}

export default CardMenu