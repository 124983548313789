import { useState, useEffect } from "react";

const useMedia = () => {

  const [deskTopScreen, setWindowWidth] = useState<boolean>(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [deskTopScreen]
}

export default useMedia