import { BottomDrawerContainer, DrawerBackground, Close } from './BottomDrawerStyles';
import {FaTimes} from 'react-icons/fa';

interface Props {
  children?: React.ReactNode,
  display: boolean,
  closeDrawer?: React.MouseEventHandler<HTMLDivElement>;
}

export const BottomDrawer = ({children, display, closeDrawer} : Props) => {
  
  return (
    <DrawerBackground display={display? 'flex':'none'} onClick={closeDrawer}>
      <BottomDrawerContainer onClick={(e: any) => e.stopPropagation()}>
        <Close onClick={closeDrawer}><FaTimes color='#000'/></Close>
        {children}   
      </BottomDrawerContainer>
    </DrawerBackground>
  )
};

export default BottomDrawer;