import styled from "styled-components";

export const Input = styled.input`
  border: none;
  outline: none;
  border-bottom: 1px solid #d8dbe1;
  padding: 5px;
  font-size: 12px;
  width: 90%;
  height: 30px;
  &:focus {
    border-bottom: 1px solid #f4ab29;
  }
`;