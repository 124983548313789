import { getQuiz, updateQuiz, generateContentQuiz } from "api/quiz";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import QuizPopup from "ui/popup/quizPopup/QuizPopup";
import Progressbar from "./components/Progressbar";
import QuestionCard from "./components/QuestionCard";
import Score from "./components/Score";
import { Body, ScoreArea, QuestionArea, AnswerArea } from "./QuizStyles";
import { updateTotalAnsweredCorrect, updateTotalAnsweredIncorrect, setData, updateQuestion, saveShownSideofQuiz } from "Redux/quiz/quizSlice";
import EndQuiz from "./components/EndQuiz";
import AnswerCardList from "./components/AnswerCardList";
import { setIsLoading } from "Redux/util/utilSlice";

type Props = {
  close?: () => void,
  moveOn: () => void,
  display?: boolean,
}

const Quiz = ({moveOn, display, close} : Props) => {

  const cards = useAppSelector((state) => state.reducer.cards)
  const quiz = useAppSelector((state) => state.reducer.quiz.data);
  const quizSettings = useAppSelector((state) => state.reducer.quiz.quizSettings)
  const [quizNo, setQuizNo] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [openDoubleCheck, setOpenDoubleCheck] = useState<boolean>(false);
  const [quizSide, setQuizSide] = useState('front')

  const submit = async (selectedAnswer: any) => {  
    const quizId = quiz.id;
    const questionId = quiz.questions[quizNo].id;
    const cardSide = getSide(quiz.questions[quizNo].cardRef.id)

    updateQuiz({
      quizId: quizId,
      questionId: questionId,
      data: 
      {
        ...quiz.questions[quizNo],
        userChoiceCardRef: {
          id: selectedAnswer.cardRef.id
        },
        shownAt: new Date(),
        shownSide: cardSide,
      },
    })
    
    if (selectedAnswer.isCorrectChoice) {
      dispatch(updateTotalAnsweredCorrect())
    } else {
      dispatch(updateTotalAnsweredIncorrect())
    }

    dispatch(saveShownSideofQuiz(quizSide))
    
    if (quiz.questions.length <= quizNo +1) {
      dispatch(setIsLoading(true))
      const res = await getQuiz({quizId: quizId})
      dispatch(setData({data:res?.data}))
      dispatch(setIsLoading(false))
      return moveOn()
    };
    await makeQuiz(quizNo+1)
    setQuizNo((prevQuizNo) => prevQuizNo + 1);
  }
  
  const handleDoubleCheck = () => {
    setOpenDoubleCheck(!openDoubleCheck)
  }
  
  useEffect(() => {
    makeQuiz(0)
  },[])
  
  const makeQuiz = async (quizNo : number) => {
    if (!quiz) return
    dispatch(setIsLoading(true))
    const quizId = quiz.id;
    const questionId = quiz.questions[quizNo].id;
    const showCardBy = quizSettings.showCardBy

    if (showCardBy === 'front') {
      setQuizSide('front')
      dispatch(updateQuestion({questionNo: quizNo, question: quiz.questions[quizNo]}))
    } else if (showCardBy === 'back') {
      setQuizSide('back')
      dispatch(updateQuestion({questionNo: quizNo, question: quiz.questions[quizNo]}))
    } else if (showCardBy === 'random') {
      const side = getSideRandomly()
      setQuizSide(side)
      dispatch(updateQuestion({questionNo: quizNo, question: quiz.questions[quizNo]}))
    } else {
      const cardSide = getSide(quiz.questions[quizNo].cardRef.id)
      const res = await generateContentQuiz({quizId, questionId, cardSide: cardSide})
      if (!res) return
      dispatch(updateQuestion({questionNo: quizNo, question: res.data}))
    }

    dispatch(setIsLoading(false))
  }

  const getSide = (cardRef: string) => {
    return checkRefCardLang(cardRef)
  }

  const checkRefCardLang = (cardRef: string) => {
    const card = cards.find((card, i) => card.id === cardRef)
    if (quizSettings.showCardBy === card?.frontLanguage.isoCode) return 'front'
    if (quizSettings.showCardBy === card?.backLanguage.isoCode) return 'back'
    if (quizSettings.showCardBy === 'front') return 'front'
    if (quizSettings.showCardBy === 'back') return 'back'
    return 'front'
  }

  const getSideRandomly = () : string => {
    if (Math.random() > 0.5) return 'front'
    else return 'back'
  }

  if (!quiz) return <></>

  return (
    <>
      <QuizPopup
        title="Quiz" 
        clickHandler={handleDoubleCheck}
        display={display}
      >
        <Body>
          <Progressbar />
          <ScoreArea>
            <Score />
          </ScoreArea>

          <QuestionArea>
            <QuestionCard 
              quizNo={quizNo}
              quizSide={quizSide}
            />
          </QuestionArea>
          <AnswerArea>
            <AnswerCardList 
              quizNo={quizNo}
              quiz={quiz}
              quizSide={quizSide}
              onClick={(question: any) => submit(question)}
            />
          </AnswerArea>
        </Body>
      </QuizPopup>

      {openDoubleCheck ?
        <EndQuiz handleDoubleCheck={handleDoubleCheck} close={close} />
      : null
      }
    </>
  )
}

export default Quiz;