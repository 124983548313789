import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { CardInterface } from 'interface';
import useMedia from 'hooks/useMedia';
import DeskTopCard from './desckTopCard/DeskTopCard';
import MobileCard from './mobileCard/MobileCard';
import { Dispatch, SetStateAction } from 'react';

export const PopupBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100%;
  height: 100%;
`;

type Props = {
  _cardList: CardInterface[],
  close: () => void,
  selectedCard: number,
  setSelectedCard: Dispatch<SetStateAction<number | undefined>>
}

const FlashCards = ({_cardList, close, selectedCard, setSelectedCard}: Props) => {

  const [deskTopScreen] = useMedia()
  const [cardList, setCardList] = useState(_cardList)

  useEffect(() => {

    const body = document.querySelector('body')
    if (!body) return
    body.style.overflow = 'hidden'

    return () => {
      body.style.overflow = ''
    }
  },[])

  return (
    <PopupBackground>
      {deskTopScreen ? 
        <DeskTopCard 
          cardList={cardList}
          selectedCard={selectedCard}
          close={close}
        />
      :
        <MobileCard 
          cardList={cardList}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          close={close}
        />
      }
    </PopupBackground>
  )
}

export default FlashCards
