import styled from "styled-components";
import { Colors } from "theme";

export const SideBarContainer = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${Colors.primary2};

  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 3px 0px 10px grey;

  .icon {
    display: none
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SideBarWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;

  position: sticky;
  top: 0px;
`;