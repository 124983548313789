import styled from "styled-components";
import Logo from 'Img/logo.svg';
import SignIn from "Components/TopMenu/SignInOut/SignIn";
import { onAuthStateChanged } from "firebase/auth"; 
import { auth } from "../firebase-config";
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import { useState } from "react";
import SigningIn from "Components/Landing/SigningIn";
import { setIsLoading } from "Redux/util/utilSlice";

const LandingContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #f4ab0b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const Landing = () => {

  const user = useAppSelector((state) => state.reducer.users);
  //Get google signin result and set user state
  onAuthStateChanged(auth, (res: any) => {
    if (auth.currentUser?.uid) {
      if (user.signin === true) {
        window.location.href='/dashboard'
      }
    }
  });

  return (
    <>
      <LandingContainer>
        <img src={Logo} width='180px' style={{margin: '30px 0px 60px 0'}}/>
        <SignIn />
      </LandingContainer>
    </>
  )
}

export default Landing;