import styled from "styled-components";
import { Colors } from "theme";

export const Container = styled.div`
  position: fixed;
  bottom: 60px;
  right: 60px;
  background-color: ${Colors.primary2};
  padding: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    bottom: 80px;
    right: 30px;
  }
`

export const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    bottom: 60px;
    right: 30px;
  }
`

export const Img = styled.img`
  width: 100%;
`