import { setLocation } from "../Redux/location/locationSlice";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../Redux/hooks';
import QuizSettingMobile from "Components/QuizSettingMobile/QuizSettingMobile";

const QuizSettingsMobile = () => {
  const dispatch = useAppDispatch();
  const location = useAppSelector((state) => state.reducer.location)

  useEffect(() => {
    //Change current location state
    dispatch(setLocation({currentPage:'quizSettings', selectedDeck: location.selectedDeck}));
  }, []);

  return (
    <QuizSettingMobile />
  );
}

export default QuizSettingsMobile;