import { AddDeckContainer, AddDeckWrapper } from "./AddDeckStyles";
import { FaPlus } from "react-icons/fa";
import { useState } from "react";
import DeckModal from "../DeckModal/DeckModal";
import { useTranslation } from "react-i18next";

const AddDeck = () => {

  const openModal = () => {
    setModal(!modal);
  };

  const [modal, setModal] = useState<boolean>(false);

  const [t] = useTranslation('deckList')

  return (
    <AddDeckContainer>
      <AddDeckWrapper onClick={openModal}>
        <div style={{marginBottom: '6px'}}><FaPlus size={25}/></div>
        <div>{t('Create a new deck')}</div>
      </AddDeckWrapper>
      {modal ? <DeckModal openModal={openModal} action='create'/> : null}
    </AddDeckContainer>
  )
}

export default AddDeck;