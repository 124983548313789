import styled from "styled-components";
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../Redux/hooks';
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth"; 
import { reset } from "../Redux/deck/deckSlice";
import { setLocation } from "../Redux/location/locationSlice";
import { useState } from "react";
import { getDecks } from "api/decks";
import DeckList from "Components/Dashboard/DeckList";
import { setIsLoading } from "Redux/util/utilSlice";
import { useTranslation } from "react-i18next";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0 0 65px;

  @media (max-width: 768px) {
    align-items: center;
    margin: 20px 0px;
  }
`;

const Dashboard = () => {

  const [t] = useTranslation('dashboard')

  type List = {
    description?: string,
    cardCount?: number,
    name?: string,
  }

  const user = useAppSelector((state) => state.reducer.users);
  const dispatch = useAppDispatch();
  const [list, setList] = useState<Array<List>>([]);

  const getDeckList = async () => {
    dispatch(setIsLoading(true))
    const response:any = await getDecks({sortField: 'last-opened-at', limit: 3})
    setList(response.data);
    dispatch(setIsLoading(false))
  };

  useEffect(() => {
    onAuthStateChanged(auth, (res: any) => {
      //Fetch Decklist
      if (res && user.userId !== '') {
        getDeckList();
      } else {
        dispatch(reset());
      }
    });
  },[])
  
  useEffect(() => {
    if (!user.signin) window.location.href='/'

    //Change current location state
    dispatch(setLocation({currentPage:'dashboard', selectedDeck: {}}));
  }, [user]);

  return (
    <DashboardContainer>
      <div style={{fontSize: '19px', fontWeight: 'bold', marginBottom: '23px'}}>
        {t('Recent activities')}
      </div>
      <div>
        <DeckList decks={list} />
        {list?.length === 0 ?
          <div style={{fontSize: '16px', color: 'grey'}}>{t('No activities')}</div>
        :null}
      </div>
    </DashboardContainer>
  )
}

export default Dashboard;