import styled from "styled-components";

export const SortingContainer = styled.div`
`;

export const SortingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SortingDropDown = styled.div`
  border: 2px solid #000;
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SortingText = styled.div`
  margin: 0.5rem;
  font-size: 13px;
`;

export const SrotingIcon = styled.div`
`;

export const AscDescIcon = styled.div`
  cursor: pointer;
  margin-left: 0.7rem;
  display: flex;
  align-items: center;
`;