import { 
  RadioContainer,
  Input,
  DisplayInput,
  DisplayInputWrapper,
  Label
} from './RadioStyles';

interface Props {
  name: string,
  label: any,
  id: any,
  value: number,
  selected?: any,
  handleSelect?: any,
  onClick?: any,
}

export const Radio = ({name, label, id, value, selected, handleSelect, onClick}: Props) => {

  const handleClick = () => {
    if(handleSelect) handleSelect(id);
    if(onClick) onClick();
  }

  return (
    <RadioContainer 
      onClick={handleClick}
    >
      <DisplayInputWrapper>
        <DisplayInput 
          checked={selected === id}
        />
        <Input 
          type='radio' 
          name={name} 
          id={id} 
          value={value} 
          checked={selected === id}
          readOnly
        />
      </DisplayInputWrapper>
      <Label>
        {label}
      </Label>
    </RadioContainer>
  )
};

export default Radio;