import styled from "styled-components";

export const Container = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;
`

export const BackImg = styled.img`
  margin-right: 15px;
  cursor: pointer;
`

export const SearchImg = styled.img`
  cursor: 'pointer';
`

export const DeckName = styled.div`
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const PlusContainer = styled.div`
  align-items: center; 
  display: flex;
  gap: 7px;
`

export const QuizContainer = styled.div`
  align-items: center;
  display: flex;
`

export const QuizImg = styled.img`
  cursor: 'pointer';
`