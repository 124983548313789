import { useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';
import { DragNDropSpace, DragNDropIcon, FileUploaderContainer, FileUploaderWrapper } from "./FileUploaderStyles";
import { useAppDispatch } from "../../Redux/hooks";
import { getDeckList } from "../../Redux/deck/deckThunk";
import { createDeck } from "api/decks";
import Import from "Img/import.svg";
import { FileUploaderModal } from "./FileUploaderModal";
import Papa from "papaparse";
import { createCard } from "api/cards";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "Redux/util/utilSlice";

const FileUploader = () => {

  const dispatch = useAppDispatch();
  const [acceptedFiles, setAcceptedFiles] = useState<Array<any>>([]);
  const [t] = useTranslation('deckList')

  //Drag and drop codes
  const onDrop = useCallback((acceptedFiles: Array<any>) => {
    setAcceptedFiles(acceptedFiles);
  }, []);
  
  const handleFileUpload = (
    acceptedFiles: Array<any>, 
    frontColumn: number, 
    backColumn: number, 
    frontLanguage: string,
    backLanguage: string
  ) => {
    const reader = new FileReader();
    reader.readAsText(acceptedFiles[0]);
    reader.onload = async () => {
      
      const valuesArray: any[] = [];
      
      Papa.parse(acceptedFiles[0], {
        header: false,
        skipEmptyLines: true,
        complete: async function (results) {
          dispatch(setIsLoading(true))

          results.data.map((d: any) => {
            valuesArray.push(Object.values(d));
          });
          //Create a new deck
          let deckResponse: any = await createDeck({
            name: acceptedFiles[0].name,
            description: '',  
          })
          let deckId: string = deckResponse.data.id;
          // Create cards iterating arr
          valuesArray.forEach( async (el: any, i: any) => {
            if (el.length > 1) {
              let [front, back] = [el[frontColumn], el[backColumn]]
              await createCard({
                deckId: deckId,
                front: front,
                back: back,
                frontLanguage: frontLanguage,
                backLanguage: backLanguage,
                tagColor: undefined
              })
            }
            if (i === valuesArray.length -1) {
              setAcceptedFiles([]);
              await dispatch(getDeckList({})).unwrap();    
            }
          })
          dispatch(setIsLoading(false))
        }
      });
    }
  }

  const closeModal = () => {
    setAcceptedFiles([]);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, 
    accept: ['text/csv', 'application/vnd.ms-excel']
  });

  return (
    <FileUploaderContainer>
      <div {...getRootProps()}>
        <FileUploaderWrapper style={isDragActive ? {backgroundColor: '#ffdea3'} : {}}>
          <DragNDropSpace>
            <DragNDropIcon>
              <img src={Import} width='30px'/>
            </DragNDropIcon>
                <div>Drag and Drop</div>
                <div style={{textDecoration: 'underline'}}>{t('Upload CSV file')}</div>
                <input {...getInputProps()} />
          </DragNDropSpace>
        </FileUploaderWrapper>
      </div>
      {acceptedFiles.length > 0 ? 
        <FileUploaderModal 
          closeModal={closeModal} 
          createDeck={handleFileUpload} 
          acceptedFiles={acceptedFiles}
        />
      :null}
    </FileUploaderContainer>
  )
};

export default FileUploader;