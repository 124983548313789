import axios from "axios";
import { auth } from "../firebase-config";
import { DeckInterface } from "interface";

export const getDecks = async (params:{
  status? : string,
  sortField? : string,
  sortDirection? : string,
  limit? : number,
}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/decks`,
      params: {
        'status': params?.status,
        'sort-field': params?.sortField,
        'sort-direction': params?.sortDirection,
        'limit': params?.limit,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    
    return response;
  } catch (e) {
    return {data: []};
  }
}

export const getDeck = async (params: 
  {
    deckId: string, 
    opened?: boolean,
  }) => {
  
  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER}/decks/${params.deckId}`,
      params: {
        opened: params.opened,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      }
    })

    return response;
  } catch (e) {
    console.error(e);
  }
}

export const createDeck = async (params: {name?: string, description?: string,}) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/decks`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      data: {
        "name": params.name,
        "description": params.description,
      }
    })
    return response;
  } catch (e) {
    console.error(e);
  }
}

export const updateDeck = async (deck:DeckInterface) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER}/decks/${deck.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      data: {
        "name": deck.name,
        "description": deck.description,
        "status": deck.status,
        "lastOpenedAt": deck.lastOpenedAt,
        // "cardCount": deck.cardCount
      }
    })
    return response;
  } catch (e) {
    console.error(e)
  }
}