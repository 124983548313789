import styled from "styled-components";

export const Deck = styled.div`
  width: 350px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 0px 1px grey;
  border-radius: 10px;
  padding: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  
  &:hover {
    scale: 1.02;
  }

  @media (max-width: 768px) {
    width: 350px;
    margin: 15px auto;
  }
`;
