import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCards } from "./cardThunk";
import { CardInterface } from 'interface';

const initialState: CardInterface[] = [];

export const cards = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    reset: () => initialState,
    setCards: (state: any, action: PayloadAction<any>) => state = action.payload
  },
  extraReducers: (builder) => {

    builder.addCase(getCards.fulfilled, (state: CardInterface[], action: PayloadAction<any>) => {
      let arr: any = [];
      action.payload.forEach((element: any, index: number) => {
        arr.push(element);
      })
      return state = arr;
    })
  }
})

export const { reset, setCards } = cards.actions;
export default cards.reducer;