import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 30px;
`;

export const ImgWrapper = styled.div`
  padding: 35px 0 130px 0;
  background-color: #f4ab0b;
  border-radius: 0 0 30% 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const ScoreSection = styled.section`
  position: absolute;
  top: 310px;
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  gap: 50px;
  border-radius: 20px;
  min-width: 250px;
  width: 40%;
  min-height: 100px;
  padding: 20px 20px;
  box-shadow: 0px 18px 22px 0px rgba(0, 0, 0, 0.05);
`

export const HistorySection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  margin-top: 90px;
  width: 100%;
`

export const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`

export const Score = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  gap: 15px;

  .score {
    font-size: 36px;
    font-weight: bold;
  }
`

export const CardContainer = styled.div`
  width: 90%;
  
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 0 5px 0 #c7c7c7;
  border-radius: 10px;
  padding: 0 15px;
  box-sizing: border-box;
`
export const Question = styled.div`
  font-weight: bold;
  font-size: 15px;
  padding: 14px;
  border-bottom: solid 1px #eeeeee;
`

export const CardWrapper = styled.div`
  font-weight: regular;
  font-size: 15px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 7px;
`
export const Answers = styled.div`
  display: flex;
  gap: 10px;

  .label{
    flex-basis: 70px;
    flex-shrink: 0;
  }

  .answer {
    color: #64d634;
  }

  .wrong-answer {
    color: #ff4545;
  }
`