import { ProfileIconContainer, ProfileIconImg } from './ProfileIconStyles';
import ProfileIconMenu from './ProfileIconMenu';
import { useState, useEffect, useRef } from 'react';
import { useAppSelector } from "../../../Redux/hooks";

const ProfileIcon = () => {

  const user = useAppSelector((state) => state.reducer.users);
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);
  const openMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  }

  //Outside click detecting
  const ref = useRef<any>();
  useEffect(()=>{
    const handleClickOutside=(event: any)=>{
      if (ref.current){
        if (!ref.current.contains(event.target)) {
        setOpenProfileMenu(false);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  return (
    <ProfileIconContainer>
      <ProfileIconImg src={user.img} onClick={openMenu} ref={ref}/>
      {openProfileMenu ? <ProfileIconMenu openMenu={openMenu} /> : null}
    </ProfileIconContainer>
  )
}

export default ProfileIcon;