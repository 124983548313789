import BottomDrawer from "ui/drawer/BottomDrawer";
import { MenuBotton } from 'ui/button/ButtonStyles';
import { useTranslation } from "react-i18next";

interface Props {
  innerMenuHandler: any,
  openDoubleCheck: any,
  openModal: any
}

const MobileMenu = ({
  innerMenuHandler,
  openDoubleCheck,
  openModal,
}: Props) => {

  const [t] = useTranslation('deckList')

  return (
    <BottomDrawer display={true} closeDrawer={innerMenuHandler}>
      <div style={{marginTop: '40px'}}>
        <MenuBotton onClick={openModal}>{t('Edit deck')}</MenuBotton>
        <MenuBotton onClick={openDoubleCheck}>{t('Delete deck')}</MenuBotton>
      </div>
    </BottomDrawer>
  )
}

export default MobileMenu