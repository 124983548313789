import { useTranslation } from 'react-i18next'
import { Container, LabelWrapper, Button } from './LanguageStyles'

const Language = () => {

  const [t, i18] = useTranslation('common')
  const isKorean = i18.language ==='ko-KR' || i18.language ==='ko'

  const onClick = (target: string) => {
    i18.changeLanguage(target)
  }

  return (
    <Container>
      <LabelWrapper>{t('Language')}</LabelWrapper>
      <Button isSelected={isKorean} onClick={() => onClick('ko-KR')}>
        한국어
      </Button>
      <Button isSelected={!isKorean} onClick={() => onClick('en-US')}>
        English
      </Button>
    </Container>
  )
}

export default Language