import styled from "styled-components";

export const MenuButtonContainer = styled.div`
  opacity: 0.9;
  cursor: pointer;
  min-width: 150px;

  :hover {
    opacity: 1;
  }
`;

export const MenuButtonWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;

  & div {
    font-size: 16px;
    margin-left: 13px;
  }
`;