import styled from "styled-components";

export const MobileTopMenuContainer = styled.div`
  background-color: #f4ab29;
  font-weight: bold;
  font-size: 26px;
  padding: 18px 25px;
`;

export const MobileTopMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
