import { useState, useEffect } from 'react';
import MobileMenu from "./components/MobileMenu";
import WebMenu from "./components/WebMenu";

interface Props {
  data?: any,
  innerMenuHandler : (event: React.MouseEvent) => void,
  openModal: (event: React.MouseEvent) => void,
  openDoubleCheck: (event: React.MouseEvent) => void,
}

const InnerMenu = ({ data, innerMenuHandler, openModal, openDoubleCheck } : Props) => {

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  return (
    <div>
      {matches &&
        <WebMenu 
          innerMenuHandler={innerMenuHandler}
          openDoubleCheck={openDoubleCheck}
          openModal={openModal}
        />
      }
      {!matches && 
        <MobileMenu 
          innerMenuHandler={innerMenuHandler}
          openDoubleCheck={openDoubleCheck}
          openModal={openModal}
        />
      }
    </div>
  )
}

export default InnerMenu;