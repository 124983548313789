import QuizPopup from "ui/popup/quizPopup/QuizPopup";
import QuizHistory from "./components/QuizHistory";
import ResultBoard from "./components/ResultBoard";

type Props = {
  close?: () =>  void,
  moveOn?: () => void,
  display?: boolean,
}

const QuizResult = ({close, display = true} : Props) => {

  return (
    <QuizPopup
      title="Quiz results" 
      clickHandler={close}
      display={display}
    >
      <ResultBoard />
      <QuizHistory />
    </QuizPopup>
  )
}

export default QuizResult;