import axios from "axios";
import { auth } from "../firebase-config";

export const generateAiDeck = async (params: 
  {
    topic: string, 
    frontLanguage: string,
    backLanguage: string,
    name: string,
    cardCount?: number,
  }) => {

  const token = await auth.currentUser?.getIdToken().then((token)=> token)

  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/decks/generate`,
      params: {
        'topic': params.topic, 
        'card-front-language': params.frontLanguage,
        'card-back-language': params.backLanguage,
        'name': params.name,
        'card-count': params.cardCount,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    return response;
  } catch (e) {
    console.error(e)
  }
}
