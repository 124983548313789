import styled from "styled-components";

const Container = styled.div`
  min-width: 200px;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 2px lightgrey;
  cursor: pointer;
  padding: 18px 0px;

  font-size: 20px;
  text-align: center;

  &:hover{
    background-color: #ffdea3;
  }
`
type Props = {
  onClick?: () => void;
  children: React.ReactNode;
  sx?: any;
}

const AnswerCard = ({onClick, children, sx}:Props) => {
  return (
    <Container onClick={onClick} style={sx}>
      {children}
    </Container>
  )
}

export default AnswerCard;