import styled from "styled-components";
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 30px 60px 90px 60px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 45%));
  justify-content: center;
  row-gap: 20px;
  column-gap: 30px;
  max-height: 375px;
  overflow-y: auto;
`;

const CardContainer = styled.div`
  max-width: 420px;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 0 5px 0 #c7c7c7;
  border-radius: 10px;
  padding: 0 15px;
  box-sizing: border-box;
`
const Question = styled.div`
  font-weight: bold;
  font-size: 15px;
  padding: 14px;
  border-bottom: solid 1px #eeeeee;
`

const Wrapper = styled.div`
  font-weight: regular;
  font-size: 15px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 7px;
`
const Answers = styled.div`
  display: flex;
  gap: 10px;

  .label{
    flex-basis: 70px;
    flex-shrink: 0;
  }

  .answer {
    color: #64d634;
  }

  .wrong-answer {
    color: #ff4545;
  }
`

const Card = ({data, quizSide}: {data: {[key: string]: any}, quizSide: string}) => {

  const [t] = useTranslation('quiz')
  if (!data) return <></>

  const shown = data.shown;
  const question = data.candidateAnswers.find((el: any) => el.isCorrectChoice === true);
  const userChoice = data.candidateAnswers.find((el: any) => el.cardRef.id === data.userChoiceCardRef.id);

  const isCorrect = question?.cardRef.id === userChoice?.cardRef.id ? true : false;

  return (
    <CardContainer>
      <Question>{t('Question')}: {shown? shown : quizSide === 'front' ? question.cardSnapshotFront : question.cardSnapshotBack}</Question>
      <Wrapper>
        <Answers>
          <div className="label">{t('Correct: ')}</div>
          <div className={isCorrect?'answer':'wrong-answer'}>{shown? question.cardSnapshotBack : quizSide === 'front' ? question.cardSnapshotBack : question.cardSnapshotFront}</div>
        </Answers>
        <Answers>
          <div className="label">{t('Selected: ')}</div>
          <div className={isCorrect?'answer':'wrong-answer'}>{shown? userChoice?.cardSnapshotBack : quizSide === 'front' ? userChoice?.cardSnapshotBack : userChoice?.cardSnapshotFront}</div>
        </Answers>
      </Wrapper>
    </CardContainer>
  )
}

const QuizHistory = () => {

  const quiz = useAppSelector((state) => state.reducer.quiz.data);
  const shownSideList = useAppSelector((state) => state.reducer.quiz.quizSettings.shownSideList)

  return (
    <Container>
      {quiz.questions.map((el: {[key: string]: any}, i: number) => (
        <Card key={i} data={el} quizSide={shownSideList[i]}/>
      ))}
    </Container>
  )
};

export default QuizHistory;