import styled from "styled-components";

export const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Header = styled.div`
  width: 240px;

  @media (max-width: 768px) {
    flex: none;
  }
`;

export const Body = styled.div`
  flex: 80 1 50%;
  
  @media (max-width: 768px) {
    flex: none;
    margin-bottom: 100px;
  }
`;