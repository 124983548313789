import { useTranslation } from "react-i18next";
import { InnerMenuContainer, InnerMenuWrapper, Text, Menu } from "./InnerMenuStyles";

interface Props {
  innerMenuHandler : (event: React.MouseEvent) => void,
  openModal: (event: React.MouseEvent) => void,
  openDoubleCheck: (event: React.MouseEvent) => void
}

const InnerMenu = ({ innerMenuHandler, openModal, openDoubleCheck } : Props) => {

  const [t] = useTranslation('common')

  return (
    <div>
      <InnerMenuContainer onClick={innerMenuHandler}>
        <InnerMenuWrapper>
          <Menu onClick={openDoubleCheck}>
            <Text>{t('Delete')}</Text>
          </Menu>
          <Menu onClick={openModal}>
            <Text>{t('Edit')}</Text>
          </Menu>
        </InnerMenuWrapper>
      </InnerMenuContainer>
    </div>
  )
}

export default InnerMenu;