import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type QuizState = {
  openSettings: boolean,
  quizSettings: {
    showCardBy: string,
    shownSideList: string[]
  },
  data: any,
}

const initialState: QuizState = {
  openSettings: false,
  quizSettings: {
    showCardBy: 'random',
    shownSideList: [],
  },
  data: {}
}

export const quiz = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizState: (state: QuizState, action: PayloadAction<any>) => {
      state.openSettings = action.payload.openSettings;
    },
    setData: (state: QuizState, action: PayloadAction<any>) => {
      state.data = action.payload.data
    },
    updateQuestion: (state: QuizState, action: PayloadAction<any>) => {
      state.data.questions[action.payload.questionNo] = action.payload.question
    },
    updateTotalAnsweredCorrect: (state: QuizState) => {
      if (state.data) {
        state.data.totalAnsweredCorrect = state.data.totalAnsweredCorrect + 1;
        state.data.totalUnanswered = state.data.totalUnanswered -1; 
      }
    },
    updateTotalAnsweredIncorrect: (state: QuizState) => {
      if (state.data) {
        state.data.totalAnsweredIncorrect = state.data.totalAnsweredIncorrect + 1;
        state.data.totalUnanswered = state.data.totalUnanswered -1; 
      }
    },
    setQuizSettings: (state: QuizState, action: PayloadAction<any>) => {
      state.quizSettings = {...state.quizSettings, ...action.payload}
    },
    saveShownSideofQuiz: (state: QuizState, action: PayloadAction<any>) => {
      state.quizSettings.shownSideList = [...state.quizSettings.shownSideList, action.payload]
    },
    resetShownSideofQuiz: (state: QuizState) => {
      state.quizSettings.shownSideList = []
    }
  }
})

export const { setQuizState, setData, updateQuestion, updateTotalAnsweredCorrect, updateTotalAnsweredIncorrect, setQuizSettings, saveShownSideofQuiz, resetShownSideofQuiz } = quiz.actions;
export default quiz.reducer;