import Popup from "ui/popup/Popup"
import {
  DeckModalContainer,
  DeckModalButtonContainer,
  Button,
  InputContainer,
  Title,
  InputWrapper,
  FlagWrapper,
} from './AiDeckModal.styles'
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { Input } from 'ui/input/InputStyles';
import { Colors } from 'theme';
import FlagSelector from "Components/FlashCard/components/flagSelector/FlagSelector";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { setFrontLanguage as _setFrontLanguage, setBackLanguage as _setBackLanguage, setIsLoading } from "Redux/util/utilSlice";
import { generateAiDeck } from "api/ai";
import { getDeckList } from "Redux/deck/deckThunk";
interface Props {
  closeModal: () => void
}

const AiDeckModal = ({
  closeModal,
}: Props) => {

  const [t] = useTranslation('deckList')
  const util = useAppSelector((state) => state.reducer.utils)
  const [commonT] = useTranslation('common')
  const [name, setName] = useState<string>('')
  const [nameValid, setNameValid] = useState<boolean>(true);
  const [topic, setTopic] = useState<string>('')
  const [cardCount, setCardCount] = useState<number>(10);
  const [frontLanguage, setFrontLanguage] = useState(util.frontLanguage ? util.frontLanguage : 'en')
  const [backLanguage, setBackLanguage] = useState(util.backLanguage ? util.backLanguage : 'en')
  const search = useAppSelector((state) => state.reducer.search)
  const dispatch = useAppDispatch()

  const handleLanguage = (side: string, lang: string) => {
    if (side === 'front') {
      setFrontLanguage(lang)
      dispatch(_setFrontLanguage(lang))
    } else {
      setBackLanguage(lang)
      dispatch(_setBackLanguage(lang))
    }
  }

  const nameInput = (event : React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 50) {
      setNameValid(true);
      setName(event.target.value);
    } else {
      setNameValid(false);
    }
  };

  const submitHandler = async () => {
    dispatch(setIsLoading(true))
    const res = await generateAiDeck({
      topic: topic, 
      frontLanguage: frontLanguage,
      backLanguage: backLanguage,
      name: name,
      cardCount: cardCount,
    })
    if (res) {
      closeModal()
      await dispatch(getDeckList({sortField:search.sort, sortDirection:search.ascDesc,})).unwrap();
    }
    dispatch(setIsLoading(false))
  }

  return (
    <Popup clickHandler={closeModal}>
      <DeckModalContainer>
        <div style={{paddingBottom: '28px'}}>
          <InputContainer>
            <Title>{t('Create deck')}</Title>
            <InputWrapper>
              <div style={{fontSize: '13px', color: `${Colors.primary2}`}}>{t('Name')}</div>
              <Input onChange={nameInput} value={name} />
              <div>
              {nameValid ? null : t('Name cannot exceed 50 characters.')}
              </div>
            </InputWrapper>
            <InputWrapper>
              <div style={{fontSize: '13px', color: `${Colors.primary2}`}}>{t('Topic')}</div>
              <Input onChange={(e) => setTopic(e.target.value)} value={topic} />
            </InputWrapper>
            <InputWrapper>
              <div style={{fontSize: '13px', color: `${Colors.primary2}`}}>{t('Number of cards')}</div>
              <Input type="number" onChange={(e) => setCardCount(Number(e.target.value))} value={cardCount} />
            </InputWrapper>
            <InputWrapper>
              <div style={{fontSize: '13px', color: `${Colors.primary2}`}}>{t('Question language')}</div>
              <FlagWrapper>
                <FlagSelector selected={frontLanguage} onClick={(ln) => handleLanguage('front', ln)} />
              </FlagWrapper>
            </InputWrapper>
            <InputWrapper>
              <div style={{fontSize: '13px', color: `${Colors.primary2}`}}>{t('Answer language')}</div>
              <FlagWrapper>
                <FlagSelector selected={backLanguage} onClick={(ln) => handleLanguage('back', ln)} />
              </FlagWrapper>
            </InputWrapper>
          </InputContainer>
        </div>
        <DeckModalButtonContainer>
          <Button color={Colors.cancel} onClick={closeModal}>{commonT('Cancel')}</Button>
          <Button 
            color={Colors.confirm} 
            onClick={submitHandler} 
            disabled={name.length > 0 && nameValid ? false : true}
          >
            {commonT('Save')}
          </Button>
        </DeckModalButtonContainer>
      </DeckModalContainer>
    </Popup>
  )
}

export default AiDeckModal