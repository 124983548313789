import { BottomMenuContainer, BottomMenuWrapper, Home, Menu, Person } from "./BottomMenuStyles";
import { useState } from "react";
import { Link } from 'react-router-dom';
import BottomDrawer from "ui/drawer/BottomDrawer";
import { MenuBotton } from 'ui/button/ButtonStyles';
import { useAppSelector } from "Redux/hooks";
import { useTranslation } from "react-i18next";

const BottomMenu = () => {

  const [t] = useTranslation('menu')

  //Open Signin Modal
  const [signInModal, setSignInModal] = useState(false);
  const openSginInModal = () => {
    setSignInModal(!signInModal);
  }

  //Open Menu
  const [menu, setMenu] = useState(false);
  const openMenu = () => {
    setMenu(true);
  };
  const closeMenu = () => {
    setMenu(false);
  }

  const location = useAppSelector((state) => state.reducer.location)
  
  return (
    <div>
      <BottomMenuContainer>
        <Link 
          to={`/dashboard`} 
          style={{display: 'flex', 
          flexDirection: 'column', 
          textDecoration: 'none',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          <Home className={location.currentPage === 'dashboard' ? "selected" : ''}/>
          <div style={{fontSize: '8px', textAlign: 'center', color: '#000', fontWeight: location.currentPage === 'dashboard' ? "bold" : ''}}>
            {t('Dashboard')}
          </div>      
        </Link>
        <Link 
          to={`/decks`} 
          style={{display: 'flex', 
          flexDirection: 'column', 
          textDecoration: 'none',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          <Menu className={location.currentPage === 'decks' ? "selected" : ''}/>
          <div style={{fontSize: '8px', textAlign: 'center', color: '#000', fontWeight: location.currentPage === 'decks' ? "bold" : ''}}>
            {t('Deck list')}
          </div>      
        </Link>
        <Link 
          to={`/mypage`} 
          style={{display: 'flex', 
          flexDirection: 'column', 
          textDecoration: 'none',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        >
          <Person className={location.currentPage === 'mypage' ? "selected" : ''}/>
          <div style={{fontSize: '8px', textAlign: 'center',  color: '#000', fontWeight: location.currentPage === 'mypage' ? "bold" : ''}}>
            {t('My page')}
          </div>      
        </Link>
      </BottomMenuContainer>
      {/* <BottomDrawer display={menu} closeDrawer={closeMenu}>
        <div style={{padding: '0 20px', fontSize: '15px'}}>
          <div style={{marginBottom: '10px', textAlign: 'left', fontSize: '18px', fontWeight:'bold'}}>Menu</div>
          <Link to='/dashboard' style={{ textDecoration: 'none', cursor: 'pointer', color:'#000' }} onClick={closeMenu}>
            <MenuBotton>Dashboard</MenuBotton>
          </Link>
          <Link to='/decks' style={{ textDecoration: 'none', cursor: 'pointer', color:'#000' }} onClick={closeMenu}>
            <MenuBotton>Decks</MenuBotton>
          </Link>
          <Link to='/mypage' style={{ textDecoration: 'none', cursor: 'pointer', color:'#000' }} onClick={closeMenu}>
            <MenuBotton>My Page</MenuBotton>
          </Link>
        </div>
      </BottomDrawer> */}
    </div>
  )
};

export default BottomMenu;