import { FilteringContainer, FilteringOption, FilteringOptionDropDown, FilteringOptionIcon, FilteringWrapper, FilteringInput, TagListContainerBox } from "./FilteringStyles";
import { FaSortDown } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../../Redux/hooks";
import { setCards } from "../../../Redux/card/cardSlice";
import { setSearch } from "../../../Redux/search/searchSlice";
import { getCards } from "../../../Redux/card/cardThunk";
import Search from "Img/search.svg";
import { searchCards } from "api/cards";
import TagList from "ui/tagList/TagList";
import { setIsLoading } from "Redux/util/utilSlice";

const Filtering = () => {

  const search = useAppSelector((state) => state.reducer.search); 
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState('');
  const [openTagContainer, setOpenTagContainer] = useState<boolean>(false);
  const [tagColor, setTagColor] = useState<string>('');
  const deckId = window.location.pathname.split('/')[2];

  const queryHandler = (event: any) => {
    setQuery(event.target.value);
  }

  const handleSearch = async (event: any) => {
    if (event.key === 'Enter' || event.type === 'click') {
      dispatch(setIsLoading(true))
      if (query.length === 0) {
        const getCardList = async () => {
          await dispatch(getCards({
            deckId: deckId, 
            sortField: search.sort, 
            sortDirection: search.ascDesc, 
            tagColor: search.tagColor,
          }))
        };
        getCardList();
      } else {
        const res = await searchCards({
          query: query,
          deckId: deckId,
          sortField: search.sort,
          sortDirection: search.ascDesc,
          tagColor: search.tagColor,
        });
        dispatch(setCards(res?.data))
      }
      dispatch(setIsLoading(false))
    }
  }

  const openTags = (boolean?: boolean) => {
    setOpenTagContainer(!boolean);
  }

  const handleChosenTag = (color: string) => {
    if (tagColor === color) {
      setTagColor('');
      dispatch(setSearch({
        tagColor: undefined
      }))
    } else {
      setTagColor(color);
      dispatch(setSearch({
        tagColor: color
      }))
    }
  }

  const ref = useRef<any>();
  useEffect(()=>{    
    //Outside click detecting
    const handleClickOutside=(event: any)=>{
      if (ref.current){
        if (!ref.current.contains(event.target)) {
          openTags(true);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  },[])

  return (
    <FilteringContainer>
      <FilteringWrapper>
      
        <FilteringInput 
          value={query} 
          onChange={queryHandler} 
          onKeyUp={handleSearch}
          placeholder='Search card'
        />

        <FilteringOptionDropDown>
          <img src={Search} onClick={handleSearch}/>
        </FilteringOptionDropDown>

        <FilteringOption onClick={() => openTags(openTagContainer)} ref={ref}>
          <FilteringOptionIcon>
            <FaSortDown />
          </FilteringOptionIcon>
          {openTagContainer && 
            <TagListContainerBox>
              <TagList 
                onClick={(tag)=> handleChosenTag(tag)}
                tagColor={[tagColor]}
              />
            </TagListContainerBox>
          }
        </FilteringOption>

      </FilteringWrapper>
    </FilteringContainer>
  )
}

export default Filtering;