import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDeckList } from './deckThunk';

interface DeckState {
  cardCount: number,
  id: string,
  name: string,
  description: string,
  role: string,
  lastOpenedAt: number,
}

const initialState: DeckState[] = [];

export const decks = createSlice({
  name: 'decks',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getDeckList.fulfilled, (state: DeckState[], action: PayloadAction<any>) => {
      let arr: any = [];
      action.payload.forEach((element: any, index: number) => {
        arr.push(element);
      })
      return state = arr;
    })
  }
})

export const {reset} = decks.actions;
export default decks.reducer;