import { Deck } from './DeckListStyles'
import DeckActivity from "Img/deckActivity.svg";
import { setLocation } from 'Redux/location/locationSlice';
import { useAppDispatch } from 'Redux/hooks';
import { useNavigate } from 'react-router-dom';
import Arrow from "Img/arrow.svg";
import { DeckInterface } from 'interface';
import { useTranslation } from 'react-i18next';

interface Props {
  decks: DeckInterface[]
}

const DeckList = ({decks} : Props) => {

  const [t] = useTranslation('dashboard')
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClick = async (data: any) => {
    dispatch(setLocation({currentPage:'decks', selectedDeck: data}));
    navigate(`/deck/${data.id}`);
  }

  return (
    <>
      {decks?.map((deck, i) => (
        <Deck key={i} onClick={() => handleClick(deck)}>
          <img src={DeckActivity} width="40px"/>
          <div style={{width: '70%'}}>
            <div style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '2px'}}>{deck.name}</div>
            <div style={{fontSize: '12px'}}>
              <div>{deck.description}</div>
              <div>{t('Total cards')}: {deck.activeCardCount}</div>
            </div>
          </div>
          <img src={Arrow} width="12px"/>
        </Deck>
      ))}
    </>
  )
}

export default DeckList