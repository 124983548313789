import { AddFlashCardContainer, AddFlashCardWrapper } from './AddCardStyles';
import { FaPlus } from 'react-icons/fa';
import { useState } from 'react';
import CardModal from './CardModal';
import { useTranslation } from 'react-i18next';

const AddCard = () => {
  const openModal = () => {
    setModal(!modal);
  };

  const [modal, setModal] = useState<boolean>(false);
  const [t] = useTranslation('deck');

  return (
    <>
      <AddFlashCardContainer onClick={openModal}>
        <AddFlashCardWrapper>
          <div>
            <FaPlus size={25} />
          </div>
          <div>{t('Create new card')}</div>
        </AddFlashCardWrapper>
      </AddFlashCardContainer>
      {modal ? <CardModal openModal={openModal} action="create" /> : null}
    </>
  );
};

export default AddCard;
