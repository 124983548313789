import Deck from 'ui/deck/Deck';
import { Title2, Body3, Body4 } from 'ui/typography/TypographyStyles';
import { LastOpen, MenuContainer } from './DeckUiStyles';
import { FaEllipsisV } from "react-icons/fa";
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ellipsisStyle: React.CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

interface Props {
  data: any,
  onClick: any,
}

const DeckUi = ({
  data,
  onClick,
}: Props) => {

  const [t, i18n] = useTranslation('deckList')
  const isKorean = i18n.language === 'ko-KR' || i18n.language === 'ko'

  //Calculate last open time
  let lastOpenedAt = '';
  const lastOpenedDB = moment(data.lastOpenedAt);
  const durationDays = Math.floor(moment.duration(moment().diff(lastOpenedDB)).asDays())
  const durationHours = Math.floor(moment.duration(moment().diff(lastOpenedDB)).asHours())
  const durationMins = Math.floor(moment.duration(moment().diff(lastOpenedDB)).asMinutes())
  const durationSeconds = Math.floor(moment.duration(moment().diff(lastOpenedDB)).asSeconds())

  const getLastOpenAt = () => {
    if (durationDays === 1) return lastOpenedAt = isKorean ? `${durationDays} 일 전 읽음` : `Read ${durationDays} day ago`;
    if (durationDays > 1) return lastOpenedAt = isKorean ? `${durationDays} 일 전 읽음` : `Read ${durationDays} days ago`;
    if (durationHours === 1) return  lastOpenedAt = isKorean ? `${durationHours} 시간 전 읽음` : `Read ${durationHours} hour ago`;
    if (durationHours >= 1) return  lastOpenedAt = isKorean ? `${durationHours} 시간 전 읽음` : `Read ${durationHours} hours ago`;
    if (durationMins === 1) return lastOpenedAt = isKorean ? `${durationMins} 분 전 읽음`: `Read ${durationMins} minute ago`;
    if (durationMins >= 1) return lastOpenedAt = isKorean ? `${durationMins} 분 전 읽음`: `Read ${durationMins} minutes ago`;
    if (durationSeconds === 1) return lastOpenedAt = isKorean ? `${durationSeconds} 초 전 읽음`: `Read ${durationSeconds} second ago`;
    else return lastOpenedAt = isKorean ? `${durationSeconds} 초 전 읽음` : `Read ${durationSeconds} seconds ago`;
  }
  getLastOpenAt();

  return (
    <Deck>
      <Title2 style={ellipsisStyle}>
        {data.name}
      </Title2>
      <Body3>
        {data.activeCardCount}{t(' cards')}
      </Body3>
      <Body3 style={ellipsisStyle}>
        {data.description ? data.description: 'No description'}
      </Body3>
      <LastOpen>
        <Body4>
          {lastOpenedAt}
        </Body4>
      </LastOpen>
      <MenuContainer>
        <FaEllipsisV 
          size={16} 
          onClick={onClick} 
          style={{color: '#000000'}}/>
      </MenuContainer>
    </Deck>
  )
}

export default DeckUi