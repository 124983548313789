import styled from "styled-components";
import { Colors } from "theme";

export const AddFlashCardContainer = styled.div`
  width: 233px;
  height: 148px;
  border: 2px solid #b4b4b4;
  border-radius: 10px;
  cursor: pointer;
  margin: 12px 26px 12px 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin: 0 0 80px 0;
  }
  `;

export const AddFlashCardWrapper = styled.div`
  font-size: 15px;
  color: ${Colors.gray2};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 310px;
    height: 91px;
  }
`;