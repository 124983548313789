import styled from "styled-components";
import AddCard from "../Components/FlashCard/AddCard";
import Card from "../ui/card/Card";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from '../Redux/hooks';
import { setLocation } from "../Redux/location/locationSlice";
import { setSearch } from "Redux/search/searchSlice";
import { getDeck } from "api/decks";
import { getCards } from "Redux/card/cardThunk";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase-config";
import { resetShownSideofQuiz, setQuizState } from "Redux/quiz/quizSlice";
import QuizModule from "Components/Quiz/QuizModule";
import FlashCards from "Components/Study/FlashCards";
import MobileMenu from "Components/Deck/MobileMenu/MobileMenu";
import MobileFilter from "Components/Deck/MobileFilter/MobileFilter";
import NoResults from "Components/Deck/NoResults/NoResults";
import { CardInterface } from "interface";
import { setIsLoading } from "Redux/util/utilSlice";

const DeckContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 65px;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 0px;
  }
`;

const Deck = () => {

  const cards = useAppSelector<CardInterface[]>((state) => state.reducer.cards);
  const user = useAppSelector((state) => state.reducer.users);
  const search = useAppSelector((state) => state.reducer.search); 
  const location = useAppSelector((state) => state.reducer.location);
  const quiz = useAppSelector((state) => state.reducer.quiz);
  const utils = useAppSelector((state) => state.reducer.utils);
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState({
    deckId: window.location.pathname.split('/')[2],
    tagColor: '',
    sort: 'created-at',
    ascDesc: 'desc',
  })

  const [cardCount, setCardCount] = useState(0);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<undefined | number>();

  const fetchCards = async () => {
    dispatch(setIsLoading(true))
    const res = await getDeck({deckId: filter.deckId, opened: true,})
    await dispatch(getCards({
      deckId: filter.deckId, 
      sortField: search.sort, 
      sortDirection: search.ascDesc, 
      tagColor: search.tagColor,
    }))
    setCardCount(res?.data.activeCardCount);
    dispatch(setIsLoading(false))
  }
  
  useEffect(() => {
    if (!user.signin) window.location.href='/'
  },[user])

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      fetchCards()
    })
    dispatch(setLocation({...location, currentPage:'cards'}));
  }, [search]);

  const apply = () => {
    const obj: any = {
      sort: filter.sort, 
      ascDesc: filter.ascDesc, 
      search: search.search, 
    }
    if (!(filter.tagColor === '')) obj['tagColor'] = filter.tagColor
    dispatch(setSearch(obj));
    setOpenDrawer(false);
  }

  const resetDrawer = (): void => {
    setFilter({
      deckId: window.location.pathname.split('/')[2],
      tagColor: '',
      sort: 'created-at',
      ascDesc: 'desc',
    })
  }

  const selectACard = (data: any) => {
    cards.forEach((el: any, i: number) => {
      if (el.id === data.id) setSelectedCard(i);
    })
  }

  const closeFlippingCard = () => setSelectedCard(undefined);

  const resetFilterSort = () => {
    resetDrawer();
    window.location.reload();
  }

  const handleQuizModalClose = () => {
    dispatch(resetShownSideofQuiz())
    dispatch(setQuizState({openSettings: false}))
  }

  return (
    <DeckContainer>

      <MobileFilter onClick={() => setOpenDrawer(true)} />

      {(cardCount === 0 && cards?.length === 0) ?
        <></>
      :null}

      {(cardCount > 0 && cards?.length === 0 && !utils.isLoading) ?
        <NoResults onClick={resetFilterSort} />
      :null}

      {(cardCount > 0 && cards?.length > 0) ?
        cards.map((card: CardInterface, index: number) => <Card key={index} data={card} clickHandler={selectACard} />)
      :null}
      <AddCard />

      <MobileMenu 
        setFilter={(obj:any) => setFilter({...filter, ...obj})}
        resetDrawer={resetDrawer}
        openDrawer={openDrawer}
        close={() => setOpenDrawer(false)}
        apply={apply}
      />

      {quiz.openSettings ? 
        <QuizModule modalHandler={handleQuizModalClose}/>
      :null}

      {selectedCard !== undefined ?
        <FlashCards 
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          _cardList={cards}
          close={closeFlippingCard}
        />
      :null}
    </DeckContainer>
  )
}

export default Deck;