import styled from "styled-components";

export const CardModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: 80%;
  padding-right: 30px;
  overflow-x: scroll;
  overflow-x: hidden;
`;

export const CardModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  `;

export const Button = styled.button`
  color: ${props => props.color};
  font-size: 14px;
  background-color: unset;
  border: none;
  margin-right: 10px;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 26px;
`;

export const MenuBotton = styled.button`
  border-radius: 10px;
  width: 100%;
  margin: 5px;
  background-color: #ffffff;
  cursor: pointer;
  padding: 13px 0;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  &.addAnother {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const LanguageWrapper = styled.div`
  margin: 8px 4px;
`