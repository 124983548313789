import {
  TopMenuContainer,
  LeftItems,
  RigthItems,
} from './DesktopMenuStyles'
import QuizButton from '../quiz/QuizButton';
import Filtering from '../FilteringSorting/Filtering';
import FilteringSorting from '../FilteringSorting/FilteringSorting';
import { useAppSelector } from 'Redux/hooks';
import ProfileIcon from '../profile/ProfileIcon';
import DeckName from './components/DeckName';
import { useTranslation } from 'react-i18next';

const DesktopMenu = () => {

  const [t] = useTranslation('menu')
  const location = useAppSelector((state) => state.reducer.location)

  return (
    <>
      <TopMenuContainer>
        <LeftItems>
          {location.currentPage === 'dashboard' && <div>{t('Dashboard')}</div>}
          {location.currentPage === 'decks' && <div>{t('Deck list')}</div>}
          {location.currentPage === 'mypage' && <div>{t('My page')}</div>}
          {location.currentPage === 'cards' && <DeckName />}
        </LeftItems>
        <RigthItems>
          {location.currentPage === 'cards' && <Filtering />}
          {(location.currentPage === 'cards' || location.currentPage === 'decks') && <FilteringSorting />}
          {location.currentPage === 'cards' ? <QuizButton /> : null}
          <ProfileIcon />
        </RigthItems>
      </TopMenuContainer>
    </>
  )
}

export default DesktopMenu