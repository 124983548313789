import { Container, Arrow, ESC, ArrowWrapper, ESCWrapper } from "./UiStyles"
import arrow from '../../desckTopCard/ui/arrow.svg'
import arrow_white from '../../desckTopCard/ui/arrow_white.svg'
import { Dispatch, SetStateAction } from 'react';

interface Props {
  onClick: (action: string) => void,
  onClose: () => void,
}

const Ui = ({ onClick, onClose } : Props) => {

  return (
    <Container>
      <ArrowWrapper>
        <Arrow id="arrow-left" direction='left' onClick={()=>onClick('arrow-left')}>
          <img src={arrow} alt="arrow" width={25}/>
        </Arrow>
        <Arrow id="arrow-right" direction='right' onClick={()=>onClick('arrow-right')}>
          <img src={arrow} alt="arrow" width={25}/>
        </Arrow>
      </ArrowWrapper>
      <ESCWrapper>
        <ESC id="esc" onClick={onClose}>
          Close
        </ESC>
      </ESCWrapper>
    </Container>
  )
}

export default Ui