import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../Redux/hooks';
import { setLocation } from "../Redux/location/locationSlice";
import Profile from 'Img/profile.svg';
import styled from "styled-components";
import SignOutModal from "Components/MyPage/SignOutModal";
import { useTranslation } from 'react-i18next';
import Language from "Components/MyPage/Language";

const MyPageContainer = styled.div`
  display: flex;
  justify-content: center;
  top: 20%;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 150px;
  }
`;

const MyPageWrapper = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
`

const ImgWrapper = styled.div`
  margin-bottom: 6px;
`

const NameWrapper = styled.div`
  font-weight: bold;
  font-size: 18px; 
  margin: 12px 0px;
`

const EmailWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 45px;
`

const Button = styled.button`
  border: 1px solid #000; 
  background-color: #fff; 
  border-radius: 25px; 
  font-weight: bold; 
  font-size: 15px; 
  padding: 17px 82px;
  height: 60px;
  width: 230px;
  box-sizing: border-box;
  cursor: pointer;
`

const MyPage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.reducer.users)
  const [signOut, setSignOut] = useState<boolean>(false);
  const [t] = useTranslation('mypage')

  useEffect(() => {
    if (!user.signin) window.location.href='/'
    if (user.signin) {
      dispatch(setLocation({currentPage:'mypage', selectedDeck: {}}));
    }
  }, [user]);

  const handleClick = () => {
    setSignOut(!signOut)
  }

  return (
    <MyPageContainer>
      <MyPageWrapper>
        <ImgWrapper><img src={Profile} width='50px' /></ImgWrapper>
        <NameWrapper>{user.name}</NameWrapper>
        <EmailWrapper>{user.email}</EmailWrapper>
        <Language />
        <Button onClick={handleClick} style={{marginTop: '36px'}}>
          {t('Sign out')}
        </Button>
      </MyPageWrapper>
      {signOut ?
        <SignOutModal closeModal={handleClick}/>
      :null}
    </MyPageContainer>
  )
};

export default MyPage;